/*TOP section start*/
.faqs-section{
    padding-top: calc(var(--left-right-padding) / 2);
    /* padding-bottom: calc(var(--left-right-padding) / 2); */
    padding-bottom: 0;
}

.faqs-section h1{
    font-size: 5vw;
}
.faqs-section p{
    font-size: 2.2vw;
    line-height: 1.25;
}
.faqs-section .row .col-md-6:last-of-type{
    display: flex;
    justify-content: center;
    align-items: center;
}
.faqs-section .row .col-md-6:last-of-type img{
    max-width: 80%;
}
/*TOP section end*/
/*Second section start*/
.faqs-second-section p{
    font-size: 1.56vw;
    line-height: 1.3;
}
/*Second section end*/

/* FAQ list start*/
.faq_heading {
    font-size: 2.2vw;
    color: var(--color-skyblue);
    cursor: pointer;
    text-transform: capitalize;
}

input {
    padding: 0.5vw 1vw;
    border: 1px solid var(--color-skyblue);
    border-radius: 6px;
    width: 100%;
}

.my_serch_btn .quote-button > button{
    font-size: 1.7vw;
}



.faq_card{
    height: 100%;
    border: 1px solid var(--color-skyblue);
    border-radius: 6px;
}

.my_faq_cat {
    margin-bottom: 1vw;
}

.view_all_link {
    margin-top: 1vw;
}

.my_faq_cat a {
    cursor: pointer;
    font-size: 1.5vw;
}

.my_faq_cat a:hover {
    color: var(--color-skyblue) !important;
}

.view_all_link a {
    text-decoration: none;
}

input {
    font-size: 1.5vw;
}
/* FAQ list end */

@media only screen and (max-width:767px){
    .faqs-section h1 {
        font-size: 12vw;
    }
    .faqs-section p {
        font-size: 5.9vw;
        line-height: 1.25;
    }
    .faqs-section .row .col-md-6:last-of-type {
        justify-content: flex-start;
    }
    .faqs-second-section p {
        font-size: 4.56vw;
        line-height: 1.3;
        margin-bottom: 5vw;
    }
    .faqs-second-section .col-md-6.img{
        display: none;
    }

    .my_faq_cat a {
        font-size: 4.5vw;
    }

    .faq_heading {
        font-size: 5.7vw;
    }

    input {
        font-size: 4vw;
    }

    .my_faq_cat {
        margin-bottom: 3vw;
    }
}
