/*Address section start*/
.contact-page.address-section{
    padding-top: 5%;
    padding-bottom: 5%;
}
.contact-page.address-section h1{
    font-size: 5.5vw;
    margin-bottom: 1.5vw;
}
.contact-page.address-section .col-md-6:first-of-type p{
    font-size: 2.15vw;
}
.contact-page.address-section .col-md-6:first-of-type p:first-of-type{
    margin-bottom: 0;
    line-height: 1;
}
.contact-page.address-section .col-md-6:first-of-type p:nth-of-type(2){
    margin-bottom: 1vw;
}
.contact-page.address-section .col-md-6:first-of-type p:nth-of-type(3){
    font-family: 'Museo 700';
    color: var(--color-skyblue);
    margin-bottom: 0;
}
.contact-page.address-section .col-md-6:first-of-type p:last-of-type{
    line-height: 1;
}
/*Address section end*/
/*Team section start*/
.contact-page.full-width{
    padding-left: 0;
    padding-right: 0;
    position: relative;
}
.contact-page.team-section img:last-of-type{
    position: absolute;
    left: 7%;
    bottom: 11%;
    width: 24.5%;
    cursor: pointer;
}
/*Team section end*/
/*Contact form section start*/
.contact-page.contact-form{
    background-color: #2A2723;
    color: white;
}
.contact-form-wrapper{
    padding-left: var(--left-right-padding);
    padding-right: var(--left-right-padding);
    padding-top: calc(var(--left-right-padding) / 2);
    padding-bottom: calc(var(--left-right-padding) / 2);
}
.contact-form-wrapper h2{
    font-size: 3.5vw;
    margin-bottom: 1.5vw;
}
.contact-form-wrapper label.control-label {
    font-size: 1.4vw;
    font-family: 'Museo 700';
    margin-bottom: 0.5vw;
}
.contact-form-wrapper input.form-control, .contact-form-wrapper textarea.form-control {
    border-radius: 0.7vw;
    font-size: 2vw;
}
.contact-form-wrapper .form-group {
    margin-bottom: 2vw;
}
.contact-form-wrapper form .row .col-md-6:first-of-type{
    padding-right: 5%;
}
.contact-form-wrapper form .row .col-md-6:nth-of-type(2){
    padding-left: 5%;
}
.contact-submit{
    background: var(--color-skyblue);
    border: none;
    border-radius: 12px;
    padding: 0.2% max(2%, 30px);
    font-size: 2vw;
    color: white;
    font-weight: 800;
    margin-top: 1.5vw;
}
/*Contact form section end*/

@media only screen and (max-width:767px){
    .contact-page.address-section{
        padding-top: 10%;
        padding-bottom: 10%;
    }
    .contact-page.address-section h1{
        font-size: 12vw;
    }
    .contact-page.address-section .col-md-6:first-of-type p{
        font-size: 5.5vw;
    }
    .contact-page.team-section img{
        width: 100%;
    }
    .contact-page.contact-form{
        padding-top: 5%;
        padding-bottom: 10%;
    }
    .contact-page.contact-form .contact-form-wrapper  > img{
        width: 65%;
        margin: 0vw auto 5vw;
    }
    .contact-form-wrapper h2 {
        font-size: 8vw;
        margin-bottom: 1.5vw;
    }
    .contact-form-wrapper form .row .col-md-6:first-of-type {
        padding-right: calc(var(--bs-gutter-x) * .5);
    }
    .contact-form-wrapper form .row .col-md-6:nth-of-type(2){
        padding-left: calc(var(--bs-gutter-x) * .5);
    }
    .contact-form-wrapper label.control-label {
        font-size: 3.65vw;
        font-family: 'Museo 700';
    }
    .contact-form-wrapper .form-group {
        margin-bottom: 4vw;
    }
    .contact-form-wrapper input.form-control, .contact-form-wrapper textarea.form-control {
        border-radius: 1.7vw;
        font-size: 3.65vw;
    }
    .contact-page.contact-form form .col-md-12.text-center {
        text-align: left!important;
        margin-top: 2vw;
    }
    .contact-submit {
        border-radius: 6px;
        padding: 1% max(2%, 30px);
        font-size: 4vw;
        margin-top: 1.5vw;
    }
    .contact-page.address-section .row .col-md-6:last-of-type{
        min-height: 45vw;
    }
}

/* Changes By Rohan start*/
@media only screen and (max-width: 601px){
    .contact-page.address-section h1{
        font-size: 8vw;
    }

    .contact-page.address-section .col-md-6:first-of-type p {
        font-size: 3.5vw;
    }
}
/* Changes By Rohan end*/

/* ADDED BY DAVID JCASP DEV. 12-10-2021 START */
/* @media only screen and (max-width: 580px){
    .contact-page.address-section h1{
        font-size: 5.5vw;
    }
} */
/* ADDED BY DAVID JCASP DEV. 12-10-2021 END */