/*TOP section start*/
.about-section{
    padding-top: calc(var(--left-right-padding) / 2);
    padding-bottom: calc(var(--left-right-padding) / 2);
}

.about-section h1{
    font-size: 5vw;
}
.about-section p{
    font-size: 2.2vw;
    line-height: 1.25;
}
.about-section .row .col-md-6:last-of-type{
    display: flex;
    justify-content: center;
    align-items: center;
}
.about-section .row .col-md-6:last-of-type img{
    max-width: 80%;
}

.about-second-section .text {
    padding-right: 3vw;
}
.about-second-section .sub_heading{
    font-style: normal;
    font-weight: 400;
    font-size: 2.1vw;
    margin-bottom: 2vw;
    margin-right: -1vw;
}

.about-logo-right{
    padding-left: 5vw;
}

.mt-about-custom {
    margin-top: 6vw;
    margin-bottom: 3vw;
}

.about-image-left {
    padding-left: 10vw;
    margin-top: 3vw;
}
/*TOP section end*/
/*Second section start*/
.about-second-section p{
    font-size: 1.4vw;
    line-height: 1.3;
}
/*Second section end*/
/*Director image section start*/
.director-image-section.full-width{
    padding-left: 0;
    padding-right: 0;
    padding-top: 1.25vw;
    width: 100%;
}

.director-image-section img {
    width: 100%;
}
/*Director image section end*/
/*Our values section start*/
.our-values-section{
    padding-top: calc(var(--left-right-padding) / 2);
    padding-bottom: calc(var(--left-right-padding) / 2);
}
.our-values-section h2{
    padding-bottom: 1vw;
    font-size: 4vw;
    color: #00a6d0;
}
.our-values-section p {
    font-size: 1.56vw;
    line-height: 1.3;
}
.quote {
    margin-bottom: 2rem;
    color: #00a6d0;
    margin-top: 2rem;
}
.quote p {
    font-size: 2.5vw;
    margin-bottom: 0;
}
.quote span {
    font-family: 'Museo 700';
    font-size: 1.75vw;
}
.mt0{
    margin-top: 0;
}
/*Our values section end*/
/*Counter section start*/
.counter-section.full-width{
    padding-right: 0;
    padding-left: 0;
    background-color: #000;
    color: #fff;
}
.counter-section .row{
    padding-left: var(--left-right-padding);
    padding-right: var(--left-right-padding);
    text-align: center;
    padding-top: calc(var(--left-right-padding) / 3);
    padding-bottom: calc(var(--left-right-padding) / 3);
}
.counter-section .row .col-md-4{
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    padding-top: calc(var(--left-right-padding) / 4);
    padding-bottom: calc(var(--left-right-padding) / 4);
}
.counter-section .row .col-md-4 span{
    font-size: 6vw;
    color: #00a6d0;
}
.counter-section .row .col-md-4 p{
    margin-bottom: 0;
    font-size: 2.5vw;
}
/*Counter section end*/
/*Video section start*/
.video-section{
    text-align: center;
    padding-top: calc(var(--left-right-padding) / 2);
    padding-bottom: calc(var(--left-right-padding) / 2);
}
.video-section h2{
    padding-bottom: calc(var(--left-right-padding) / 2);
    font-size: 5vw;
}
.video-player{
    width: 90% !important;
    height: 35vw !important;
    overflow: hidden;
    margin: 0 auto;
}
/*Video section end*/
/*Single trust pilot start*/
.single-trustpilot{
    text-align: center;
    padding-top: 5%;
    padding-bottom: 5%;
}
.single-trustpilot h2{
    font-size: 3.5vw;
}
.single-trustpilot p{
    font-size: 2.3vw;
    line-height: 1.3;
}
.single-trustpilot .reviewer{
    font-family: 'Museo 700';
    font-size: 1.7vw;
    margin-bottom: 2.5vw;
}
.single-trustpilot img{
    max-width: 20%;
}
/*Single trust pilot end*/
/*CES culture start*/
.about-page .section-title{
    font-size: 3.5vw;
}
.about-page p:not(.trustpilot-slide p){
    /* font-size: 2.2vw  */
}
.about-page p.content{
    font-size: 1.65vw;
    line-height: 1.2;
}
.price-guarantee.about-page .price-button{
    padding: 0;
}
.price-guarantee.about-page .price-content.col-md-5{
    padding-right: 0;
}
.price-guarantee.about-page .price-images.col-md-7{
    padding-left: 0;
}
.price-guarantee.about-page .price-images > img:first-of-type {
    width: 65%;
    left: -10%;
}
.price-guarantee.about-page .price-images > img:last-of-type {
    width: 40%;
}
/*CES culture end*/
/*Still unsure start*/
.about-page .quote-button{
    margin-top: 3vw;
    margin-bottom: 3vw;
}
/*Still unsure end*/

/* ADDED BY DAVID JCASP DEV. 12-10-2021 START*/
p strong{
    font-weight: 900;
}
/* ADDED BY DAVID JCASP DEV. 12-10-2021 END*/
@media only screen and (max-width:767px){
    .about-section h1 {
        font-size: 12vw;
    }
    .about-section p {
        font-size: 5.9vw;
        line-height: 1.25;
    }
    .about-section .row .col-md-6:last-of-type {
        justify-content: flex-start;
    }
    .about-second-section p {
        font-size: 4.56vw;
        line-height: 1.3;
        margin-bottom: 5vw;
    }
    .about-second-section .col-md-6.img, .about-image-left{
        display: none;
    }
    .our-values-section h2 {
        font-size: 10vw;
    }
    .our-values-section p {
        font-size: 4.56vw;
        line-height: 1.3;
    }
    .quote p {
        font-size: 5.46vw;
        margin-bottom: 2vw;
    }
    .quote span {
        font-size: 3.75vw;
    }
    .counter-section .row .col-md-4{
        border-top: none;
        border-bottom: none;
    }
    .counter-section .row .col-md-4:first-of-type, .counter-section .row .col-md-4:nth-of-type(2) {
        border-top: 1px solid white;
        width: 50%;
        float: left;
    }
    .counter-section .row .col-md-4 span {
        font-size: 8vw;
    }
    .counter-section .row .col-md-4 p {
        margin-bottom: 0;
        font-size: 5vw;
    }
    .counter-section .row .col-md-4:last-of-type {
        border-bottom: 1px solid white;
    }
    .counter-section .row .col-md-4:last-of-type p {
        padding-left: 20%;
        padding-right: 20%;
    }
    .video-section h2 {
        font-size: 9vw;
    }
    .video-player {
        height: 45vw !important;
    }
    .single-trustpilot {
        padding-top: 2%;
    }
    .single-trustpilot h2 {
        font-size: 5.5vw;
    }
    .single-trustpilot p {
        font-size: 3.35vw;
        line-height: 1.5;
        margin-bottom: 1vw;
    }
    .single-trustpilot .reviewer {
        font-size: 3.4vw;
    }
    .price-guarantee.about-page, .trustpilot.full-width.about-page{
        display: none;
    }
    .about-page.faq-section p{
        font-size: 3vw !important;
    }

    .single-trustpilot {
        padding-top: 8%;
    }

    .about-second-section .sub_heading{
        font-size: 5.1vw;
        margin-bottom: 6vw;
    }

}