.boilerpage h2 {
  font-size: 4.5vw;
}
/*Boilers section start*/
.boilerpage.boilers-section {
  padding-top: var(--left-right-padding);
  margin-top: calc(var(--left-right-padding) / -2);
  margin-bottom: calc(var(--left-right-padding) / 2);
}
.boilerpage h1 {
  font-size: 6vw;
}
.boilerpage p {
  font-size: 1.534vw;
}
.boilers-section .img-part {
  text-align: right;
}
.boilers-section .img-part img {
  margin-top: -40%;
  max-width: 86%;
}
/*Boilers section end*/
/*Green IQ section start*/
.boilerpage.full-width {
  padding-left: 0;
  padding-right: 0;
}

.boilerpage.greeniq-section {
  background: url("../../../assets/images/boilerpage/Focus-IQ-Extended.jpg")
    no-repeat center;
  background-size: 100% 100%;
}
.boilerpage.greeniq-section > img {
  position: absolute;
  z-index: -1;
}
.boilerpage .column {
  padding: 10% 10% 5%;
}
.boilerpage .column h2 {
  font-size: 4.5vw;
  margin-bottom: 1.8vw;
}
.boilerpage .column span {
  color: var(--color-green);
}
.boilerpage .column > p {
  max-width: 40vw;
}
.boilerpage .column > ul {
  padding-left: 2rem;
  font-size: 1.4vw;
  max-width: 33vw;
}
.boilerpage .column > ul > li {
  margin-bottom: 1vw;
}
.boilerpage .quote-button > button {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 2vw;
}
.space_iq_center {
  padding-left: 17vw;
}
.pos-relative {
  position: relative;
}
.absolute_case_study_cercle {
  position: absolute;
  bottom: 2%;
  right: 10%;
  width: 38vw;
  cursor: pointer;
}
/*Green IQ section end*/
/*Combi boiler section start*/
.boilerpage.boiler-combi {
  background: url("../../../assets/images/boilerpage/Combi-background.png")
    no-repeat center;
  background-size: 100% 100%;
  padding-top: 5%;
  padding-bottom: 10%;
  color: #fff;
}
.boilerpage.boiler-combi > h2 {
  margin-bottom: 10%;
}
.boilerpage.boiler-combi p {
  font-size: 1.9vw;
  line-height: 1.2;
}
.boilerpage.boiler-combi .row .col-md-6:first-of-type img {
  max-width: 83%;
  margin-top: 4%;
}
/*Combi boiler section end*/
/*System boiler section start*/
.boilerpage.system-boilers {
  background: url("../../../assets/images/boilerpage/System-background-sq.png")
    no-repeat center;
  background-size: 100% 100%;
  padding-top: 12%;
  padding-bottom: 12%;
  color: white;
  margin-top: -6%;
  clip-path: polygon(0% 0%, 14.5% 12.8%, 100% 0%, 100% 85%, 85% 100%, 0% 85%);
}
.boilerpage.system-boilers p {
  font-size: 1.85vw;
  line-height: 1.2;
}
.boilerpage.system-boilers .col-md-6:last-of-type {
  text-align: right;
}
.boilerpage.system-boilers .col-md-6:last-of-type img {
  max-width: 83%;
  margin-top: 4%;
}
/*System boiler section end*/
/*Regular boiler section start*/
.boilerpage.regular-boilers {
  background: url("../../../assets/images/boilerpage/Regular-background-sq.png")
    no-repeat center;
  background-size: 100% 100%;
  padding-top: 12%;
  padding-bottom: 12%;
  color: white;
  margin-top: -7.3%;
  clip-path: polygon(0% 0%, 85% 16.5%, 100% 0%, 100% 85%, 15% 100%, 0% 85%);
}
.boilerpage.regular-boilers p {
  font-size: 1.85vw;
  line-height: 1.2;
}
.boilerpage.regular-boilers .col-md-6:first-of-type img {
  max-width: 83%;
  margin-top: 4%;
}
/*Regular boiler section end*/
/*Clean sectoin start*/
.boilerpage.clean-section {
  padding-top: 5%;
}
.boilerpage.clean-section h2 {
  margin-bottom: 2vw;
}
.boilerpage.clean-section p {
  font-size: 1.6vw;
}
.boilerpage.clean-section li {
  font-size: 1.6vw;
}
.boilerpage.clean-section .row > .col-md-6:nth-of-type(2) {
  text-align: right;
}
.boilerpage.clean-section .row > .col-md-6:nth-of-type(2) img {
  max-width: 90%;
  margin-top: 4%;
}
.boilerpage.clean-section .partner-txt {
  margin-top: 2vw;
}
.boilerpage.clean-section .partner-img {
  max-width: 45%;
}
.boilerpage.clean-section .quote-button > button {
  margin-top: 5vw;
  margin-bottom: 1vw;
}
/*Clean sectoin end*/
/*Monitoring and smarttech section start*/
.boilerpage.monitoring-section {
  background-color: #2a2723;
  /* color: white; */
  padding-top: 15%;
  padding-bottom: 10%;
  /* clip-path: polygon(0 0, 50% 12.5%, 100% 0, 100% 85%, 50% 100%, 0 85%); */
  clip-path: polygon(0 0, 50% 12.5%, 100% 0, 100% 90%, 50% 100%, 0 90%);
  background: url("../../../assets/images/boilerpage/24-7-Monitoring-Pic-Extended-2-top.jpg")
    no-repeat center;
  background-size: 100% 100%;
}
.boilerpage.monitoring-section h2,
.boilerpage.smarttech-section h2 {
  margin-bottom: 3vw;
}
.boilerpage.monitoring-section p,
.boilerpage.smarttech-section p {
  line-height: 1.4;
  /* font-size:  1.595vw; */
  font-size: 1.45vw;
}
.boilerpage.monitoring-section li,
.boilerpage.smarttech-section li {
  font-size: 1.65vw;
  line-height: 1.35;
  margin-bottom: 1.5vw;
}
.boilerpage.monitoring-section .quote-button > button,
.boilerpage.smarttech-section .quote-button > button {
  margin-top: 3vw;
}
.boilerpage.smarttech-section {
  background: url("../../../assets/images/boilerpage/Smart-tech-background-min.png")
    no-repeat center;
  background-size: 100% 100%;
  color: white;
  margin-top: -9%;
  padding-top: 55%;
  padding-bottom: 10%;
}
/*Monitoring section and smarttech end*/
/*Get Quote section start*/
.boilerpage.get-quote{
  background: url("../../../assets/images/boilerpage/Boiler-cta-background.png") no-repeat center;
  background-size: cover;
  background-position: unset;
  padding-top: 53%;
  padding-bottom: 5%;
  margin-top: -7%;
  z-index: 0;
  clip-path: polygon(50% 8%, 100% 0, 100% 100%, 0 100%, 0 0);
  position: relative;
  /* margin-bottom: -8%; */
}
.boilerpage.get-quote h2 {
  padding-left: 5%;
  padding-right: 5%;
}
.boilerpage.get-quote .quote-button > button {
  margin-top: 5vw;
}
/*Get Quote section end*/

@media only screen and (max-width: 767px) {
  /* DAVID ADDED FOR BOILER IMAGE ALIGN LEFT 18-10-21 START */
  .boilerpage.boilers-section img{
    width: 50%;
  }
  /* DAVID ADDED FOR BOILER IMAGE ALIGN LEFT 18-10-21 END */

  .boilerpage h1 {
    font-size: 12vw;
  }
  .boilerpage p {
    font-size: 4vw;
  }
  .boilers-section .text-part img {
    width: 50%;
    margin-right: -10%;
    margin-top: 5%;
    margin-left: 5%;
    margin-bottom: 5%;
  }
  .boilerpage .column {
    padding: 60% 5% 11.5%;
  }
  .boilerpage .column h2 {
    font-size: 8.7vw;
    margin-bottom: 2.8vw;
  }
  .boilerpage .column > ul,
  .boilerpage .column > p {
    max-width: unset;
  }
  .boilerpage .column > ul > li {
    margin-bottom: 2vw;
    font-size: 4.645vw;
  }
  .boilerpage.greeniq-section .quote-button {
    text-align: center;
  }
  .boilerpage.greeniq-section > img {
    height: 200%;
  }
  .boilerpage.greeniq-section {
    background: url("../../../assets/images/boilerpage/Focus-IQ-Extended-2-long-2.jpg")
      no-repeat center;
    /* background-size: 100% 100%; */
    background-size: contain;
    background-position: top;
    background-color: #dadfe2;
  }
  .boilerpage.greeniq-section {
    /* background: url("../../../assets/images/boilerpage/IQ-Background-mobile.png") no-repeat center; */
    /* background-size: 100% 100%; */
  }
  .space_iq_center {
    margin-bottom: 5vw;
  }
  .boilerpage.boiler-combi {
    background-image: url("../../../assets/images/boilerpage/Combi-background-mobile.png");
    margin-top: -16.5%;
    padding-top: 13%;
    text-align: center;
    padding-bottom: 15%;
  }

  .boilerpage.monitoring-section {
    background-image: url("../../../assets/images/boilerpage/24_7_monitroing-section-old.png");
  }

  .space_iq_center {
    padding-left: unset;
  }

  .boilerpage.boiler-combi h2 {
    font-size: 8vw;
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
  }
  .boilerpage.boiler-combi .col-md-6:last-of-type h2 {
    margin-top: 4vw;
  }
  .boilerpage.boiler-combi p {
    font-size: 4.5vw;
    text-align: center;
  }
  .boilerpage.boiler-combi .row .col-md-6:first-of-type img {
    max-width: 65%;
  }
  .boilerpage.boiler-combi .col-md-6:last-of-type .quote-button {
    text-align: center;
  }
  .boilerpage.system-boilers {
    background-image: url("../../../assets/images/boilerpage/System-background-mobile.png");
    clip-path: none;
    margin-top: -8.5%;
    text-align: center;
    padding-top: 15%;
    padding-bottom: 15%;
  }
  .boilerpage.system-boilers h2 {
    font-size: 8vw;
  }
  .boilerpage.system-boilers p {
    font-size: 4.5vw;
  }
  .boilerpage.system-boilers .col-md-6:last-of-type {
    text-align: center;
  }
  .boilerpage.system-boilers .col-md-6:last-of-type img {
    max-width: 65%;
    margin-bottom: 8vw;
  }
  .boilerpage.system-boilers > .row {
    flex-direction: column-reverse;
  }
  .boilerpage.regular-boilers {
    background-image: url("../../../assets/images/boilerpage/Regular-background-mobile.png");
    clip-path: none;
    margin-top: -8.5%;
    text-align: center;
    padding-top: 15%;
    padding-bottom: 20%;
  }
  .boilerpage.regular-boilers h2 {
    font-size: 8vw;
  }
  .boilerpage.regular-boilers p {
    font-size: 4.5vw;
  }
  .boilerpage.regular-boilers.col-md-6:first-of-type {
    text-align: center;
  }
  .boilerpage.regular-boilers .col-md-6:first-of-type img {
    max-width: 65%;
    margin-bottom: 8vw;
  }
  .clean-section .col-md-6:first-of-type img:first-of-type {
    width: 63%;
    margin-right: -32%;
    margin-top: 5%;
    margin-left: 0%;
  }
  .boilerpage.clean-section li {
    font-size: 4.645vw;
  }
  .clean-section h2 {
    font-size: 8vw;
  }
  .boilerpage.clean-section p {
    font-size: 4.645vw;
  }
  .boilerpage.clean-section .partner-txt {
    margin-bottom: 1vw;
  }
  .boilerpage.clean-section .quote-button > button {
    margin-top: 10vw;
    margin-bottom: 5vw;
  }
  .boilerpage.monitoring-section,
  .boilerpage.smarttech-section {
    text-align: center;
  }
  .boilerpage.monitoring-section h2,
  .boilerpage.smarttech-section h2 {
    font-size: 8vw;
  }
  .boilerpage.monitoring-section p,
  .boilerpage.smarttech-section p {
    font-size: 4.645vw;
  }
  .boilerpage.monitoring-section li,
  .boilerpage.smarttech-section li {
    font-size: 4.645vw;
    text-align: left;
  }
  .boilerpage.monitoring-section {
    padding-top: 15%;
    padding-bottom: 15%;
    clip-path: polygon(0 0, 50% 4%, 100% 0, 100% 96%, 50% 100%, 0 96%);
    position: relative;
    z-index: 1;
  }
  .boilerpage.smarttech-section {
    margin-top: -53%;
    padding-top: 200%;
    padding-bottom: 15%;
    background-size: 100% 100%;
    background-image: url("../../../assets/images/boilerpage/Smart-tech-background-mobile.png");
    clip-path: polygon(0 0, 100% 0%, 100% 0, 100% 98%, 50% 100%, 0 98%);
    z-index: 0;
    position: relative;
  }
  .boilerpage.get-quote {
    padding-bottom: 10%;
    margin-top: -8%;
    background-size: 115% 100%;
    /* padding-top: 70%; */
    background-position: unset;
    background-size: 100% 100%;
  }
}

/* Changes By Rohan start*/
@media only screen and (max-width: 601px) {
  .boilerpage.smarttech-section {
    background-size: 100% auto;
    margin-top: -16%;
    padding-top: 88%;
  }
}
/* Changes By Rohan end*/

/* ADDED BY DAVID JCASP DEV. 12-10-2021 START */
@media only screen and (max-width: 580px) {
  /* .boilers-section .only-for-mobile{
        height: 50%;
    } */
    .boilerpage.boilers-section img{
        /* float: left; */
        width: 50%;
        /* margin: 0 1rem 0.2rem 0; */
    }

  .only-for-mobile.cursor-pointer {
    text-align: center;
  }

  .only-for-mobile.cursor-pointer img {
    /* text-align: center; */
    margin-left: 1rem;
    width: 65%;
    height: 65%;
  }

  .boilerpage.greeniq-section .quote-button {
    margin-bottom: 3.5rem;
    margin-top: 0.5rem;
  }
  .smarttech-section {
    background-size: 100% 100%;
  }
  .boilerpage.get-quote {
    padding-bottom: 40%;
    z-index:0;
  }
  .boilerpage.get-quote h2 {
    position: relative;
    top: 4rem;
  }
  .boilerpage.get-quote a {
    position: relative;
    top: 4rem;
    /* z-index: 999; */
  }
  /* .boilers-section .img-part img{
        margin-top: 0;
    } */

    .boilerpage.get-quote{
      padding-bottom: 40%;
      z-index:0;
      clip-path: polygon(50% 6%, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

@media only screen and (max-width: 580px){
    .boilerpage.get-quote{
        clip-path: polygon(50% 5%, 100% 0, 100% 100%, 0 100%, 0 0);
    }
}

/* ADDED BY DAVID JCASP DEV. 12-10-2021 END */
