  
  .image img {
    position: absolute;
    top: 49%;
    left: 46%;
    height: 64px;
    width: 64px;
  }
  
  .spin {
    position: relative;
    display: inline-block;
    width: 350px;
    height: 350px;
    border: 2px solid #34495e;
    border-radius: 100%;
    animation: spin 0.75s infinite linear;
  }
  
  .spin::before,
  .spin::after {
    left: -2px;
    top: -2px;
    display: none;
    position: absolute;
    content: '';
    width: inherit;
    height: inherit;
    border: inherit;
    border-radius: inherit;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .spinner,
  .spinner::before,
  .spinner::after {
    display: inline-block;
    border-color: transparent;
    animation-duration: 1.2s;
  }
  
  .spinner::before {
    transform: rotate(120deg);
  }
  
  .spinner::after {
    transform: rotate(240deg);
  }

  @media (max-width:767px){
    .image img{
      left: 46%;
    }
  }
  
  @media (max-width:480px) {
    .image img{
      left: 42%;
    }
    .spin {
      width: 270px;
      height: 270px;
    }
    /* .image img {
      position: absolute;
      top: 19%;
      left: 21%;
    } */
  }