.quote-button > button{
    background: var(--color-skyblue);
    border: none;
    border-radius: 12px;
    padding: 0.5% max(2%, 20px);
    font-size: 2.5vw;
    color: white;
    font-weight: 800;
    transition: 0.5s all;
} 

.skyblueglow:hover{
    background-color: var(--color-skyblue);
    box-shadow: 0 0 10px 3px var(--color-skyblue);
}

.greenglow:hover{
    background-color: var(--color-green);
    box-shadow: 0 0 10px 3px var(--color-green);
}

.whiteglow:hover{
    background-color: #fff;
    box-shadow: 0 0 10px 3px #fff;
}

@media only screen and (max-width: 768px){
    .quote-button > button{
        padding: 2% max(2%, 20px);
    }
}