.RFS-StepContainer {
  color: white;
  padding-left: calc(var(--bs-gutter-x) * .2) !important;
  padding-right: calc(var(--bs-gutter-x) * .2) !important;
}

.RFS-StepperContainer {
  padding: initial !important;
}

.RFS-StepMain {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #90BF35;
  padding: 1vw;
}

[class*='RFS-StepButton-'] {
  background-color: transparent !important;
  width: auto !important;
  height: auto !important;
}

button.RFS-StepButton[class*='RFS-StepButton-'].steps:before {
  color: white;
  width: 2vw;
  height: 2vw;
  object-fit: contain;
  content: "";
}
.RFS-StepContainer:nth-of-type(1) button.RFS-StepButton[class*='RFS-StepButton-'].steps:before {
  background: url("../../../assets/images/steps/1.svg") no-repeat center;
  background-size: 100% 100%;
}
.RFS-StepContainer:nth-of-type(2) button.RFS-StepButton[class*='RFS-StepButton-'].steps:before {
  background: url("../../../assets/images/steps/2.svg") no-repeat center;
  background-size: 100% 100%;
}
.RFS-StepContainer:nth-of-type(3) button.RFS-StepButton[class*='RFS-StepButton-'].steps:before {
  background: url("../../../assets/images/steps/3.svg") no-repeat center;
  background-size: 100% 100%;
}
.RFS-StepContainer:nth-of-type(4) button.RFS-StepButton[class*='RFS-StepButton-'].steps:before {
  background: url("../../../assets/images/steps/4.svg") no-repeat center;
  background-size: 100% 100%;
}
.RFS-StepContainer:nth-of-type(5) button.RFS-StepButton[class*='RFS-StepButton-'].steps:before {
  background: url("../../../assets/images/steps/5.svg") no-repeat center;
  background-size: 100% 100%;
}
.RFS-StepContainer:nth-of-type(6) button.RFS-StepButton[class*='RFS-StepButton-'].steps:before {
  background: url("../../../assets/images/steps/6.svg") no-repeat center;
  background-size: 100% 100%;
}
.RFS-StepContainer button.RFS-StepButton[class*='RFS-StepButton-'].completed.steps:before {
  background: url("../../../assets/images/steps/Completed\ tick\ 1.png") no-repeat center;
  background-size: 100% 100%;
}
.RFS-StepContainer:nth-of-type(1) button.RFS-StepButton[class*='RFS-StepButton-'].active.steps:before {
  background: url("../../../assets/images/steps/1-white.svg") no-repeat center;
  background-size: 100% 100%;
}
.RFS-StepContainer:nth-of-type(2) button.RFS-StepButton[class*='RFS-StepButton-'].active.steps:before {
  background: url("../../../assets/images/steps/2-white.svg") no-repeat center;
  background-size: 100% 100%;
}
.RFS-StepContainer:nth-of-type(3) button.RFS-StepButton[class*='RFS-StepButton-'].active.steps:before {
  background: url("../../../assets/images/steps/3-white.svg") no-repeat center;
  background-size: 100% 100%;
}
.RFS-StepContainer:nth-of-type(4) button.RFS-StepButton[class*='RFS-StepButton-'].active.steps:before {
  background: url("../../../assets/images/steps/4-white.svg") no-repeat center;
  background-size: 100% 100%;
}
.RFS-StepContainer:nth-of-type(5) button.RFS-StepButton[class*='RFS-StepButton-'].active.steps:before {
  background: url("../../../assets/images/steps/5-white.svg") no-repeat center;
  background-size: 100% 100%;
}
.RFS-StepContainer:nth-of-type(6) button.RFS-StepButton[class*='RFS-StepButton-'].active.steps:before {
  background: url("../../../assets/images/steps/6-white.svg") no-repeat center;
  background-size: 100% 100%;
}

[class*='RFS-StepButtonContent-'] {
  display: none;
}
.RFS-Label{
  margin-top: 0.4vw !important;
  font-size: 1.18vw;
}

.blue-bg {
  background: #00A6D0 !important;
}

.grey-bg {
  background: #F3F3F5 !important;
  color: grey;
}

@media only screen and (max-width: 767px){
  .survey.stepper-info > .row{
    justify-content: center;
  }
  button.RFS-StepButton[class*='RFS-StepButton-'].steps:before{
    width: 5vw;
    height: 5vw;
  }
  span.RFS-Label[class*="RFS-Label-"]{
    display: none;
  }
  .RFS-StepMain{
    padding: 2vw;
  }
  .RFS-StepperContainer{
    width: 75% !important;
  }
  .RFS-StepContainer {
    padding-left: calc(var(--bs-gutter-x) * .1) !important;
    padding-right: calc(var(--bs-gutter-x) * .1) !important;
  }
}