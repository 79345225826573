.survey{
    padding-top: 5%;
    padding-bottom: 5%;
}
.question-section .iconDiv{
    width: 100%;
    /* background: url("../../../assets/images/"); */
}
.question-section h2{
    font-size: 4vw;
    margin-bottom: 5%;
    line-height: 1;
}
.question-section .row{
    justify-content: center;
}
.question-section .row .col-md-3 img{
    max-width: 75%;
}
.question-section .row .col-md-3 p{
    font-size: 2.6vw;
    margin-top: 1vw;
    margin-bottom: 3vw;
    line-height: 1.3;
}
.go-to-previous{
    justify-content: center;
    align-items: center;
    font-size: 1.5vw;
    font-family: 'Museo 700';
    margin: 1vw 0 2vw 0;
    color: #b4b5b7;
    cursor: pointer;
}
.go-to-previous.question{ /* ADDED BY DAVID 20-10-2021 */
    justify-content: start;
}
.go-to-previous img{
    max-width: max(3.5vw, 40px);
}
.question-section .option *, .go-to-previous *, .restart-survey *{
    pointer-events: none;
}
.question-section .option, .restart-survey{
    cursor: pointer;
}
.question-section .option.selected, .question-section .option.selected p{
    font-family: 'Museo 700';
}
.question-section{
    display: none;
}
.question-section.current-question{
    display: block;
}
.cssanimation{
    animation-duration: 1s;
    animation-fill-mode: both;
}
.fadeInBottom { animation-name: fadeInBottom }
@keyframes fadeInBottom {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to { opacity: 1 }
}

@media only screen and (max-width:767px) {
    .survey{
        padding-top: 8%;
        /* padding-bottom: 10%; */
        padding-bottom: 5%;  /* ADDED BY DAVID 18-10-2021 */
    }
    .question-section h2{
        font-size: 9vw;
        margin-bottom: 10%;
        line-height: 1.2;
    }
    .question-section .col-md-3.option {
        width: 33.33%;
    }
    .question-section .row .col-md-3 p {
        font-size: 4vw;
        margin-top: 3vw;
        margin-bottom: 6vw;
    }
    .go-to-previous{
        font-size: 4vw;
        margin: 4vw 0 2vw 0 !important;
    }
}

/* Extra info page start*/
.survey.extrapage {
    padding: 0;
}

.survey .pl-section {
    padding-left: var(--left-right-padding);
}

.pt-heading{
    padding-top: 5%;
}

.survey h2 {
    font-size: 4vw;
    line-height: 6vw;
}

.survey p {
    font-size: 1.5vw;
}

.survey .big_size{
    font-size: 2vw;
}

.chat_image {
    width: 4vw;
    padding-right: 1vw;
}
.survey .full_image{
    vertical-align: top;
}

.text-left{
    justify-content: start;
}

@media only screen and (max-width:767px) {
    .chat_image {
        width: 8vw;
    }

    .survey .pl-section {
        padding-left: var(--left-right-padding);
        padding-right: var(--left-right-padding);
    }

    .survey h2 {
        font-size: 9vw;
        line-height: 9vw;
    }

    .survey p {
        font-size: 4vw;
    }

    .survey .big_size {
        font-size: 5vw;
    }
    
    .survey .quote-button {
        margin-bottom: 10px;
    }

    .mobile-margin-top-8{
        margin-top: 8vw !important;
    }
    .go-to-previous.question{ /* ADDED BY DAVID 20-10-2021 */
        margin: 0.5vw 0;
        justify-content: center;
    }
}
/* Extra info page end */