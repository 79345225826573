.navbar-transparent{
    /* background-color: transparent; */
    /* background-color: transparent; */
    background-color: var(--color-blue);
    padding: 20px var(--left-right-padding);
    /* position: fixed; */
    width: 100%;
    z-index: 1;
} 
.navbar-transparent.not-homepage{
    background: url("./../../../assets/images/aboutus/Header-Background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* min-height: 200px; */
    /* min-height: 174px; */
    min-height: 12vw;
}
.navbar.not-homepage{
    padding-bottom: 5rem !important;
} 
.nav-item.logo{
    max-width: 300px;
}
.nav--link{
    width: 300px;
}
.nav-item.menu-icon{
    max-width: 24px;
    margin-right: 10px;
}
/* .nav-item.menu-icon:hover{
    background-color: #91BD1F;
    box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.9);
} */

.nav-item.trustpilot{
    max-width: 150px;
}
.nav-menu-icon{
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 20px;
    cursor: pointer;
}

.menu-link {
    display: flex;
    color: #FFF;
    cursor: pointer;
}
/* MENU ICON CHANGE FOR GLOW */
.menu-link.glow:hover > img{
    border-radius: 50%;
    color: #fff;
    background-color: #91BD1F;
    box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.9);
}
.my_menu:hover ~ img{
    border-radius: 50%;
    color: #fff;
    background-color: #91BD1F;
    box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.9);
}

.my_menu > .dropdown-menu{
    list-style: none;
    background-color: #04093B;
    color: #FFF;
    padding: 1vw 2vw;
    min-width: 0vw;
}

.my_menu > .dropdown-menu li a {
    text-decoration: none;
    color: #FFF;
}

.my_menu > .dropdown-menu li a:hover {
    color: #669C39;
    /* background-color: #91BD1F; */
    text-shadow: 0 0 10px 5px #aeee7a;
    font-weight: 700;
}


@media only screen and (min-width:768px) and (max-width:1024px){
    .navbar.not-homepage{
        padding-bottom: 3rem !important;
    }
    .navbar-transparent.not-homepage{
        min-height: 140px;
    }
}
@media only screen and (max-width:767px){
    .navbar-transparent{
        padding: 20px var(--left-right-padding-mobile);
        background-color: var(--color-blue);
        /* background-color: #fff; Change done by David 19-10-2021 */
    }
    .nav-item.trustpilot{
        display: none;
    }
    .nav--link {
        width: 60%;
    }
    /* .nav-item.logo{ comment by David cause the nav-text out of the nav-box
        max-width: 100%;
    } */
    .navbar{
        height: 107px;
    }
    .navbar-transparent.not-homepage{
        background-image: url("../../../assets/images/Header-Background-mobile.png"); /*, linear-gradient(180deg, rgba(0,9,61,1) 0%, rgba(255,255,255,1) 35%)*/
        min-height: 25vw;
        padding-top: 1rem;
    }

    .my_menu{
        width: 25%;
    }
}
@media only screen and (max-width: 580px){
    .nav-item.logo{
        width: 80%;
    }

}
