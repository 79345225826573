/*Steps section start*/
section.result.stepper-info {
  padding-top: 5%;
}
.stepper-info .col-md-2 .icon {
  width: 2vw;
  height: 2vw;
  margin: 0 auto;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}
.stepper-info .col-md-2 {
  padding-left: calc(var(--bs-gutter-x) * 0.2);
  padding-right: calc(var(--bs-gutter-x) * 0.2);
}
.stepper-info .col-md-2 .step-wrapper {
  text-align: center;
  padding: 1vw;
}
.stepper-info .completed {
  background-color: #90bf35;
  color: white;
}
.stepper-info .active {
  background-color: #00a6d0;
  color: white;
}
.stepper-info .pending {
  background-color: #f3f3f5;
}
.stepper-info .col-md-2:nth-of-type(5) .icon {
  background: url("../../../assets/images/resultspage/Step\ 5.png");
}
.stepper-info .col-md-2:nth-of-type(6) .icon {
  background: url("../../../assets/images/resultspage/Step\ 6.png");
}
.stepper-info .completed .icon {
  background: url("../../../assets/images/resultspage/Completed\ tick\ 1.png");
}
.stepper-info .step-name {
  margin-top: 0.4vw;
  font-size: 1.19vw;
}
.survey-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  color: grey;
  margin-top: 1vw !important;
  font-weight: bolder;
  font-size: 1.3vw;
}
.survey-buttons > div {
  display: flex;
  align-items: center;
}
.survey-buttons > div:last-of-type:not(:first-of-type) {
  margin-left: 5vw;
}
.survey-buttons > div:not(.review-edit) > svg {
  margin-right: 0.5vw;
  transform: rotate(60deg);
}
.review-edit {
  cursor: pointer;
}
.review-edit.active {
  color: black;
  background-color: transparent;
  user-select: none;
}
.edit-questions {
  padding-right: calc(var(--bs-gutter-x) * 0.25) !important;
  padding-left: calc(var(--bs-gutter-x) * 0.25) !important;
  padding-top: 4%;
  padding-bottom: 5%;
}
.edit-questions .col-md-6:nth-child(4n + 1),
.edit-questions .col-md-6:nth-child(4n + 2) {
  background-color: #f3f3f5;
}
.edit-questions .row {
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
}
.edit-questions .col-md-2 {
  color: var(--color-skyblue);
}
.edit-questions .col-md-6 {
  position: relative;
}
.edit-questions .col-md-6:nth-child(2n + 1) .row:after {
  display: block;
  content: "";
  background-color: black;
  height: 80%;
  width: min(0.15%, 1px);
  position: absolute;
  top: 10%;
  right: 0;
}
.edit-questions .edit-btn {
  cursor: pointer;
}
/*Steps section end*/
/* ADDED BY DAVID@JCASP ON 23-10-2021 START */
/* .number-label{
  text-align: left;
  margin-top: -6vw !important;
  padding-top: 1.5vw;
} */
/* .number-label span{
  padding: 0.1em 0.4em;
  border-radius: 50%;
  font-size: 2.3vw;
  font-family: 'Museo 700';
  background: var(--color-green);
  color: #fff;
} */
.recommentNumber .number {
  background: var(--color-green);
  width: 3vw;
  height: 3vw;
  font-size: 2.3vw;
  /* font-family: 'Museo 700'; */
  border-radius: 50%;
  margin-top: -4.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.recommentNumber .number span {
  color: #fff;
  font-family: 'Museo 700';
}
/* ADDED BY DAVID@JCASP ON 23-10-2021 START */
/*Recommended boiler start*/
.recommended-boiler {
  margin-top: 2vw;
}
.recommended-boiler h1 {
  font-size: 4.5vw;
  text-align: center;
  margin-bottom: 2vw;
}
.boiler-card {
  /* background: #F3F3F5; */
  background: #ffffff;
  position: relative;
  padding: 18% 15% 5%;
  /* padding: 18% 5% 5%; */
  /* margin-top: -8%; */
  /* margin-top: -8.5%; */
  margin-top: -7.4%;
  /* clip-path: polygon(0 0, 50% 12.5%, 100% 0, 100% 85%, 50% 100%, 0 85%); */
  clip-path: polygon(0 0, 50% 12.5%, 100% 0, 100% 87.5%, 50% 100%, 0 87.5%);
}
.boiler-card:before {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 10%;
  width: 80%;
  height: 100%;
  content: "";
  background-color: #f3f3f5;
}
.boiler-card.one-line-title:not(.first-boiler) {
  clip-path: polygon(0 0, 50% 13.5%, 100% 0, 100% 86.5%, 50% 100%, 0 86.5%);
}
.boiler-card.first-boiler {
  clip-path: polygon(50% 100%, 100% 85%, 100% 0, 0 0, 0 85%);
  padding-top: 5%;
  margin-top: 0;
}
.boiler-card.first-boiler.one-line-title {
  clip-path: polygon(50% 100%, 100% 83.5%, 100% 0, 0 0, 0 83.5%);
}
.boiler-card .row .col-md-3.has-best-buy {
  padding-top: 3%;
  position: relative;
}
.boiler-card .row .col-md-3 img.grant-img {
  max-width: 85%;
}
.boiler-card .row .col-md-3 img.best_buy_logo {
  position: absolute;
  max-width: 50%;
  top: 0;
  right: 5%;
}
.boiler-card .row .col-md-3 img.special_offer_logo {
  position: absolute;
  max-width: 50%;
  top: 21vw;
  right: 5%;
}
/* added by david@JCASP 29-10-2021 start*/
.boiler-card .row .col-md-3 img.interest_free_logo {
  width: 80%;
  margin-top: 33%;
  margin-left: 4%;
}
/* added by david@JCASP 29-10-2021 start*/
.recommended-boiler h2 {
  font-size: 4vw;
}
.recommended-boiler p {
  font-size: 2vw;
}
.recommended-boiler .grey-card {
  background: #636569;
  border-radius: 10px;
  /* padding: 1.5vw; */
  padding: 2vw;
  color: white;
}
.recommended-boiler .grey-card .grey-card-lower {
  display: flex;
  align-items: baseline;
}
.text-part {
  width: 80%;
}
.grey-title {
  /* font-size: 2.25vw; */
  font-size: 2vw;
  line-height: 1;
}
.grey-subtitle {
  /* font-size: 1.8vw; */
  margin-bottom: 0.5vw;
  font-size: 1.6vw;
}
.grey-subtitle span {
  /* font-size: 3vw; */
  font-size: 2.9vw;
  font-family: "Museo 700";
}
.grey-card .quote-button button {
  font-size: 2vw;
  /* padding: 0.75% 15px; */
  padding: 1vw 1.5vw;
  line-height: 1;
}
.grey-card .quote-button:last-of-type button {
  min-width: 18vw;
}
.grey-card .quote-button:first-of-type {
  width: auto;
  padding-right: 0;
}
.grey-card .quote-button:last-of-type {
  flex: 1;
}
.recommended-boiler .icon-part {
  /* width: 20%; */
  width: 18.75%;
}
.recommended-boiler .icon-part img {
  /* max-width: 80%; */
}
.recommended-boiler .email-quote {
  margin: 1.5vw 0 0;
  display: flex;
  align-items: baseline;
}
.recommended-boiler .email-quote > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* font-size: 1.5vw; */
  font-size: 1.65vw;
}
.recommended-boiler .email-quote > div:first-of-type {
  /* flex: 0.8; */
  flex: 1 1;
}
.recommended-boiler .email-quote > div:last-of-type {
  flex: 1;
}
.recommended-boiler .email-quote > div > img {
  /* max-width: 2.5vw; */
  /* margin-right: 0.5vw; */
  max-width: 2.8vw;
  margin-right: 0.9vw;
}
.safe-installtion.flexbox.best-fit-bottom {
  margin-top: 3vw;
  /* margin-bottom: 3vw; */
}
.boiler-card > .row {
  padding-bottom: 4vw;
}
/*Recommended boiler end*/

/* Email quote modal START */
.my_subscribe .mark {
  margin-top: 0.5vw;
}
/* Email quote modal END */
.reasonFor {
  padding: 5vw 10vw;
  font-size: 3vw;
  text-align: center;
}
.vaillantGreenIpBatch {
    position: absolute;
    max-width: 100%;
    width: 25%;
    right: 4%;
    top: 37.5%;
    transform-origin: center;
    transform: translateY(-50%);
}
/* .vaillantGreenIpBatch{
    display: none;
} */
@media only screen and (max-width: 767px) {
  .reasonFor {
    /* ADDED BY DAVID 20-10-21 */
    font-size: 5vw;
  }
  .recommentNumber .number{ /* ADDED BY DAVID 23-10-2021 */
    /* text-align: left; */
    margin-top: unset;
    width: 5vw;
    height: 5vw;
    font-size: 4vw;
  }

  .survey-buttons {
    font-size: 4vw;
    /* margin-top: 2vw !important;
        margin-bottom: 2vw; */
    margin-top: 5vw !important;
    margin-bottom: -1vw;
  }
  .survey-buttons .restart-survey,
  .survey-buttons .review-edit {
    color: #404040;
    /* color : #00a5cf; */
    /* border:1px solid #00a5cf; */
    padding: 0 0.3em;
    border-radius: 3px;
    box-shadow: #404040 0px 1px 4px;
    /* box-shadow: #00a5cf 0px 1px 4px; */
  }

  /* .survey-buttons .restart-survey:hover,
    .survey-buttons .review-edit:hover{
        color:green;
        border:1px solid #00a5cf;
        padding: 0 0.3em;
        box-shadow: #0089ac 0px 1.6px 4px;
    } */
  /* ADDED BY DAVID 20-10-2021 FOR MORE INFO BUTTON START*/
  /* .grey-card .quote-button:last-of-type button{
        min-width: 24vw;
    } */
  .recommended-boiler .email-quote > div:first-of-type {
    background: #404153;
    padding: 1.5vw 0;
    font-weight: 700;
    border-radius: 5px;
    margin-right: 1.5vw;
    display: flex;
    flex-direction: column;
  }
  .recommended-boiler .email-quote > div:last-of-type {
    background: #404153;
    padding: 1vw 0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    top: 1px;
  }
  /* ADDED BY DAVID 20-10-2021 FOR MORE INFO BUTTON END*/
  .survey-buttons > div > svg {
    margin-right: 1.5vw !important;
  }
  .result.stepper-info > .row {
    justify-content: center;
  }
  section.result.stepper-info {
    padding-top: 8%;
    padding-bottom: 5%;
  }
  .edit-questions .col-md-7 {
    width: 55%;
  }
  .edit-questions .col-md-3 {
    width: 25%;
  }
  .edit-questions .col-md-2 {
    width: 20%;
  }
  .edit-questions {
    padding-top: 8%;
  }
  .edit-questions .col-md-6:nth-child(2n + 1) .row:after {
    display: none;
  }
  .edit-questions .col-md-6:nth-child(4n + 1),
  .edit-questions .col-md-6:nth-child(4n + 2) {
    background-color: unset;
  }
  .edit-questions .col-md-6:nth-child(odd) {
    background-color: #f3f3f5;
  }
  .recommended-boiler h1 {
    font-size: 8vw;
    /* margin-bottom: 10vw; */
    margin-bottom: 6vw; /* ADDED BY DAVID 18-10-2021 FOR LEADING SPACE REDUCE */
    padding-left: 5%;
    padding-right: 5%;
  }
  .boiler-card .col-md-3 {
    text-align: center;
  }
  .boiler-card .row .col-md-3 img.grant-img {
    max-width: 45%;
  }
  .boiler-card .row .col-md-3 {
    padding-top: 6%;
  }
  .boiler-card .row .col-md-3 img.best_buy_logo {
    max-width: 25%;
    right: 17%;
  }
  /* .boiler-card .row .col-md-3 img.special_offer_logo{
        max-width: 25%;
        right: 23%;
        top:42vw;
    } */
  .boiler-card .row .col-md-3 img.special_offer_logo {
    max-width: 32%;
    right: 12%;
    top: 32vw;
  }
  /* Added by David@Jcasp 29-10-2021 start */
  .boiler-card .row .col-md-3 img.interest_free_logo {
    position: absolute;
    width: 25%;
    top: -23%;
    right: 72%;
  }
  /* Added by David@Jcasp 29-10-2021 end */
  .recommended-boiler h2 {
    font-size: 6.5vw;
    margin-top: 7vw;
    text-align: center;
  }
  .recommended-boiler p {
    font-size: 4.5vw;
    text-align: center;
  }
  .recommended-boiler .grey-card {
    padding: 3vw;
    position: relative;
  }
  .grey-title {
    font-size: 2.4vw;
  }
  .grey-card .quote-button:last-of-type button {
    min-width: unset;
    width: 95%;
  }
  .grey-subtitle {
    margin-top: 1vw;
    margin-bottom: 2vw;
    font-size: 2.6vw;
  }
  .grey-subtitle span {
    font-size: 4vw;
  }
  .grey-card .quote-button button {
    font-size: 2.8vw;
    /* padding: 1.5vw 2vw; */
    padding: 2.5vw 2.3vw;
    margin-top: 1.5vw;
    border-radius: 6px;
  }
  .grey-card .quote-button:last-of-type {
    padding-left: calc(var(--bs-gutter-x) * 0.25);
  }
  .grey-card .quote-button:last-of-type {
      padding-left: 1vw;    
  }
  .recommended-boiler .email-quote {
    margin: 2.5vw 0 0;
  }
  .recommended-boiler .email-quote > div > img {
    max-width: 4.5vw;
    margin-right: 0.9vw;
  }
  .recommended-boiler .email-quote > div {
    /* font-size: 2.65vw; */
    font-size: 2.4vw;
  }
  /* ADDED BY DAVID 20-10-2021 FOR MORE INFO START */
  .recommended-boiler .email-quote .cwu {
    position: relative;
    top: 2.5px;
  }
  /* ADDED BY DAVID 20-10-2021 FOR MORE INFO END */
  .safe-installtion.flexbox.best-fit-bottom {
    margin-top: 7vw;
    margin-bottom: 15vw;
    justify-content: center;
  }
  .best-fit-bottom .covid-icon {
    max-width: 12.5vw;
  }
  .boiler-card {
    margin-top: -15%;
    clip-path: polygon(0 0, 50% 9%, 100% 0, 100% 91%, 50% 100%, 0 91%);
  }
  .boiler-card.one-line-title:not(.first-boiler) {
    clip-path: polygon(0 0, 50% 9%, 100% 0, 100% 91%, 50% 100%, 0 91%);
  }
  .boiler-card.first-boiler {
    clip-path: polygon(50% 100%, 100% 90%, 100% 0, 0 0, 0 90%);
    padding-top: 7%;
  }
  .vaillantGreenIpBatch {
      position: absolute;
      width: 30%;
      right: 1%;
      top: 16%;
      transform-origin: center;
      transform: translateY(-50%);
  }
}

/* Finance options Start */
.pos-relative {
  position: relative;
}
.pos-absolute {
  position: absolute;
  top: -4vw;
  right: -12.5vw;
  display: none;
}
.boiler-card.one-line-title .pos-absolute {
  top: -10vw;
}
.pos-absolute.show {
  display: block;
}

.finance-section {
  width: 27vw;
  /* min-height: 81vh; */
  border-radius: 20px;
  background-color: #ffffff;
  border: 0.2vw solid #00a5cf;
}

.finance-close-icon {
  color: #00a5cf;
  font-weight: bold;
  font-family: "Arial";
  position: absolute;
  right: -0.7vw;
  top: -1vw;
  background: #fff;
  border-radius: 33%;
  background-color: #ffffff;
  border: 0.2vw solid #00a5cf;
  padding: 7px 14px;
  cursor: pointer;
}

.finance-box {
  padding: 30px;
  text-align: left;
}

.finance-box h1 {
  font-size: 2.7vw;
  color: #00a5cf;
  text-align: left;
}

.finance-box p {
  font-size: 1.2vw;
  color: #636569;
}

.finance-box .control-label {
  width: 100%;
  font-size: 1.6vw;
  color: #00a5cf;
}

.finance-box .form-group {
  margin-bottom: 10px;
}

.finance-box .range-slider {
  width: 100%;
  height: 3vw;
  background-color: white;
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  background: transparent;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;

  background: #636569;
  border: none;
}

.finance-box input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 2vw;
  width: 2vw;
  border-radius: 50%;
  background: #00a5cf;
  margin-top: -0.7vw;
}

input[type="range"]::-ms-track {
  width: 100%;
  cursor: pointer;
}

select {
  margin-top: 1.5vw;
  padding: 0.5vw 1vw;
  font-size: 1.2vw;
  border: 1px solid var(--color-skyblue);
  background: white;
  border-radius: 6px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("../../../assets/images/Down-scroll.svg") no-repeat right;
  background-size: 50% 50%;
  background-position-x: 140%;
}
select::-ms-expand {
  display: none;
}

.finance-box select:focus,
.finance-box input:focus {
  outline: none;
}

.finance-box .info p {
  margin-bottom: 0px;
}

.finance-box .text-right {
  float: right;
}

.recommended-boiler {
  padding: 0;
}

@media only screen and (max-width: 780px) {
  .finance-section {
    width: 28vw;
  }
}

@media only screen and (max-width: 767px) {
  .pos-absolute,
  .boiler-card.one-line-title .pos-absolute {
    top: -29vw;
    right: 0vw;
  }

  .finance-close-icon {
    top: -4vw;
  }

  .finance-section {
    width: 75vw;
  }
  .finance-box h1 {
    font-size: 7vw;
    margin-bottom: 12px;
  }

  .finance-box {
    padding: 22px;
  }

  .finance-box .control-label {
    font-size: 4vw;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    background: #636569;
    border: none;
  }

  .finance-box input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 4vw;
    width: 4vw;
    border-radius: 50%;
    background: #00a5cf;
    margin-top: -1.2vw;
  }

  input[type="range"]::-ms-track {
    width: 100%;
    cursor: pointer;
  }

  .finance-box p {
    font-size: 4vw;
    text-align: left;
  }
}

@media only screen and (max-width: 580px) {
  .finance-box input[type="range"]::-webkit-slider-runnable-track {
    height: 3px;
  }
  .finance-box input[type="range"]::-webkit-slider-thumb {
    height: 4vw;
    width: 4vw;
    margin-top: -1.7vw;
  }
  /* .survey-buttons .restart-survey,
    .survey-buttons .review-edit{
        margin: 1em 0 -2em 0;
    } */
  .recommended-boiler .icon-part img {
    position: relative;
    right: -5px;
  }
  /* .range-slider input{
        padding-block: 2em;
    } */
  .grey-card .quote-button button {
    font-size: 2.8vw;
  }
  .col-12.white-date-box .mt-4.text-center .quote-button {
    margin: 0.8rem;
  }
  .boiler-card .row .col-md-3 img.best_buy_logo {
    right: 18%;
  }
  .boiler-card .row .col-md-3 img.special_offer_logo {
    max-width: 40%;
    right: 5%;
  }
}
/* Finance options End */
/* ADDED BY DAVID 18-10-2021 TO GIVE SPACE BETWEEN SURVEY AND REVIEW START*/
@media only screen and (max-width: 767px) {
  .survey-buttons {
    font-size: 4vw;
    /* margin-top: 2vw !important;
        margin-bottom: 2vw; */
    margin-top: 5vw !important;
    margin-bottom: -1vw;
  }
}
/* ADDED BY DAVID 18-10-2021 TO GIVE SPACE BETWEEN SURVEY AND REVIEW END*/
.loader-section {
  min-height: 50vh;
  margin-top: 30vh;
}
@media only screen and (max-width: 380px) {
  .survey-buttons .restart-survey,
  .survey-buttons .review-edit {
    font-size: 3vw;
    margin-top: 0.5rem;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
  }
  .grey-card .quote-button button {
    font-size: 2.8vw;
  }
  .boiler-card .row .col-md-3 img.interest_free_logo {
    top: -22%;
  }
}
