/*TOP section start*/
.blog-section{
    padding-top: calc(var(--left-right-padding) / 2);
    padding-bottom: calc(var(--left-right-padding) / 2);
}

.blog-section h1{
    font-size: 5vw;
}
.blog-section p{
    font-size: 2.2vw;
    line-height: 1.25;
}
.blog-section .row .col-md-6:last-of-type{
    display: flex;
    justify-content: center;
    align-items: center;
}
.blog-section .row .col-md-6:last-of-type img{
    max-width: 80%;
}
/*Share button start*/
.share_buttons{
    display: flex;
    margin-bottom: 1vw;
}

.share_buttons a {
    text-decoration: none;
    padding: 0.5vw 1vw;
    /* border-radius: 7vw; */
    border: 1px solid transparent;
}

.share_buttons a:hover{
    color: white;
}

.facebook_btn {
    background-color: #4267B2;
    color: white;
}

.twitter_btn {
    background-color: #55acee;
    color: white;
}

.whatsapp_btn {
    background-color: #25d366;
    color: white;
}

.messanger_btn {
    background-color: #448AFF;
    color: white;
}

.share_buttons a span {
    padding-left: 0.5vw;
}

.my_blog {
    cursor: pointer;
    padding: 1.5vw 0;
    font-size: 1.15vw;
    margin: 0 auto;
}

.white-box-blog {
    margin-top: 1vw;
}

.r-b-heading {
    font-size: 1.5vw;
}

.blog_details .my_html p > *, .blog_details .my_html a > * {
    font-size: 1.56vw !important;
    line-height: 1.3
}

.blog_details .my_html a {
    text-decoration: none;
}
/* Share button end */

@media only screen and (max-width:820px){
    .share_buttons a span {
        display: none;
    }
}

@media only screen and (max-width:767px){
    .blog-section h1 {
        font-size: 12vw;
    }
    .blog-section p {
        font-size: 5.9vw;
        line-height: 1.25;
    }
    .blog-section .row .col-md-6:last-of-type {
        justify-content: flex-start;
    }
    .r-b-heading {
        font-size: 4vw;
    }

    .blog_details .my_html p > *, .blog_details .my_html a > * {
        font-size: 4.56vw !important;
        line-height: 1.3;
    }
}

