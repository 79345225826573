.price-guarantee .section-title{
    font-size: 3.5vw;
}
.price-guarantee p{
    font-size: 2.2vw;
}
.price-guarantee p.content{
    font-size: 1.65vw;
    line-height: 1.2;
}
.price-guarantee .price-button{
    padding: 0;
}
.price-guarantee .price-content.col-md-5{
    padding-right: 0;
}
.price-guarantee .price-images.col-md-7{
    padding-left: 0;
}
.price-guarantee .price-images > img:first-of-type {
    width: 65%;
    left: -10%;
    position: absolute;
    bottom: 0;
}
.price-guarantee .price-images > img:last-of-type {
    width: 40%;
    position: absolute;
    right: 0;
    bottom: 10%;
}
.price-guarantee .quote-button {
    margin-top: 3vw;
    margin-bottom: 3vw;
}
.price-guarantee{
    background: url('./../../../assets/images/grey-gradient.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 3.5vw;
}
.price-images{
    position: relative;
}
.price-content p{
    font-size: 2.65vw;
    line-height: 1;
}

@media only screen and (max-width:767px){
    .price-guarantee p.content{
        font-size: 4vw;
        line-height: 1.3;
    }
    .price-guarantee{
        min-height: 100vh;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        background: #5F99AF;
        overflow-x: hidden;
    }
    .price-content{
        order: 2;
        min-height: 46vh;
        padding-left: var(--left-right-padding-mobile) !important;
        padding-right: var(--left-right-padding-mobile) !important;
        padding-top: 2vw;
        padding-bottom: 4vw;
        color: #ffffff;
        text-align: center;
    }
    .price-images{
        position: relative;
        height: max(54vh, 300px);
        order: 1;
        padding-left: 5%;
        background-color: rgb(59,136,158);
        padding-right: 5%;
        margin: 0 auto;
        clip-path: polygon(50% 100%, 100% 90%, 100% 0, 0 0, 0 90%);
    }
    .price-images > img:nth-child(2){
        width: auto;
        position: absolute;
        bottom: 0;
        left: 50%;
        right: 0;
        transform: translateX(-50%);
    }
    .price-guarantee .section-title {
        font-size: 11.5vw;
    }
    .price-content p {
        font-size: 5.7vw;
        /* line-height: 4vh; */
        padding-left: var(--left-right-padding-mobile);
        padding-right: var(--left-right-padding-mobile);
    }
    .price-guarantee .quote-button > button {
        padding: 0.5% max(3%, 30px);
        background: #ffffff !important;
        font-size: 4.5vw;
        color: var(--color-skyblue) !important;
    }
    .price-guarantee .price-button{
        padding: 1vw 0 0 0;
    }
}