.footer-top {
  background: url("./../../../assets/images/footer-fibre-optic.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: white;
  padding-top: 10%;
  padding-bottom: 1%;
  -webkit-clip-path: polygon(0 20%, 80% 0, 100% 20%, 100% 100%, 0 100%);
  clip-path: polygon(0 20%, 80% 0, 100% 20%, 100% 100%, 0 100%);
}
.footer-top .address-title {
  margin-bottom: 0;
  font-size: 1.25vw;
  font-weight: 800;
}
.footer-top .address,
.footer-top .email {
  font-weight: 600;
  font-size: 1.17vw;
}
.footer-top .tel {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.17vw;
}
.footer-top a {
  color: white;
  text-decoration: none;
}
.footer-top .further-title {
  font-size: 1.55vw;
  font-weight: 900;
  margin-bottom: 0.7rem;
}
.footer-top .quick-link {
  font-weight: 800;
  margin-bottom: 0.7rem;
  font-size: 1.17vw;
}
.footer-top > .row > .col-md-12 {
  font-size: 1vw;
}
.footer-section-title {
  font-size: 1.5vw;
}
.footer-bottom .row .col-md-6:first-of-type .img-box:last-of-type img {
  /* max-width: 80%; */
  min-width: 90%;
}
.footer-bottom .row .col-md-6:first-of-type .img-box:first-of-type img {
  width: 100%;
  max-width: 80%;
}
.footer-bottom .row .col-md-6:first-of-type {
  border-right: 2px solid grey;
}
.footer-bottom .row .col-md-6:last-of-type {
  padding-left: 5%;
}
.footer-bottom .row .col-md-6:last-of-type .img-box:first-of-type {
  margin-right: 10px;
}
.footer-bottom .img-box {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  align-items: flex-end;
  width: auto;
  /* Changes By Rohan start*/
  height: 3vw;
  margin-right: 1vw;
  /* Changes By Rohan end*/
}
.footer-bottom .img-box {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  align-items: flex-end;
  width: auto;
  /* Changes By Rohan start*/
  height: 2.65vw;
  margin-right: 2vw;
  /* Changes By Rohan end*/
}
/* Changes By Rohan start*/
.footer-bottom .box-container {
  margin-bottom: 2.5vw;
}
.footer-bottom .box-container.award .img-box {
  height: 4vw;
  margin-right: 4vw;
}
/* Changes By Rohan end*/

.footer-bottom .img-box img {
  height: inherit;
}
.footer-bottom {
  padding-top: 60px;
  padding-bottom: 60px;
}
/* Changes By Rohan start*/
/* .footer-bottom .row:first-of-type {
  margin-bottom: 50px;
} */
/* Changes By Rohan end*/

.new-award-section {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.old-award-section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

/* //Footer Design added by David@JCasp 20-10-2021 */
.box-container.award .till-desktop{
  transform: scale(1.1);
}

@media only screen and (max-width: 768px) {
  .footer-top .further-title,
  .footer-top .address-title {
    font-size: 2.5vw;
  }
  .footer-section-title {
    font-size: 3vw;
  }
}
.only-for-mobile {
  display: none;
}

@media (max-width: 767px) {
  .footer-top > .row > .col-md-12.developBy_Text{
    font-size: 3vw;
  }
  .footer-top {
    clip-path: polygon(0 10%, 50% 0, 100% 10%, 100% 100%, 0 100%);
    text-align: center;
    padding-top: 15%;
    /* min-height: 60vh; */
  }
  .footer-top .row {
    height: 100%;
  }

  .footer-top .further-title,
  .footer-top .address-title {
    font-size: 5vw;
  }
  .footer-section-title {
    /* font-size: 3vw; */
    font-size: 4vw;
    margin-bottom: 2vw;
  }
  .footer-bottom {
    text-align: center;
  }
  .footer-bottom .row .col-md-6:first-of-type {
    border-right: none;
  }
  .footer-bottom .img-box {
    justify-content: center;
  }
  .footer-bottom .row .col-md-6:last-of-type {
    margin-top: 5vw;
  }
  .footer-bottom .row:first-of-type {
    /* margin-bottom: 9vw; */
  }
  .footer-bottom .row:last-of-type .col-md-12 .only-for-mobile {
    margin: 0 auto;
    margin-top: 4vw;
  }
  .footer-top .address,
  .footer-top .email,
  .footer-top .tel {
    font-size: 4vw;
  }
  .footer-top .further-section .further-title {
    display: none;
  }

  .only-for-desktop {
    display: none;
  }

  .only-for-mobile {
    display: block;
  }

  .footer-top .quick-link {
    font-size: 3vw;
  }

  .further-section.only-for-mobile {
    padding-bottom: 5vw;
  }
  .footer-top .nav-item.logo {
    max-width: 300px;
    margin-bottom: 2vw;
  }
  .till-desktop{
    transform: scale(1.1);
  }
}

.pr-1 {
  padding-right: 0.5vw;
}

.pl-1 {
  padding-left: 0.5vw;
}

/* Changes By Rohan start*/
/* .custom_award_space {
  padding: 0 1vw;
} */
/* Changes By Rohan end*/

.custom_award_space:nth-child(1) {
  padding-left: 0vw;
}

.custom_award_space:nth-child(4) {
  padding-right: 0vw;
}

/* Changes By Rohan start*/
@media only screen and (max-width: 601px) {
  .footer-bottom .box-container {
    margin-bottom: 6vw;
    justify-content: center;
  }
  .footer-bottom .box-container .flexbox{
    justify-content: center;
    height: 100%;
  }
  .footer-bottom .box-container.award .flexbox {
    flex-direction: column;
  }
  .footer-bottom .box-container.award .new-award-section .img-box {
    height: 12vw;
    margin-right: 4vw;
  }
  .footer-bottom .box-container.award .old-award-section .img-box {
    height: 10vw;
    margin-right: 4vw;
  }
  .footer-bottom .img-box {
    height: 5.5vw;
    margin-right: 3vw;
  }
  .footer-bottom .img-box:last-child {
    margin-right: 0;
  }
  .footer-bottom .row:first-of-type {
    margin-bottom: 0;
  }
  .old-award-section {
    margin-bottom: 0;
    margin-top: 2vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* DAVID ADDED NEW ADDED AWARD DECREASE IMPORTANCE 18-10-21 START  */
  .new-award-section img{
    transform: scale(0.82);
  }
  /* DAVID ADDED NEW ADDED AWARD DECREASE IMPORTANCE 18-10-21 END  */
}
/* Changes By Rohan end*/
/* ADDED BY DAVID 20-10-2021 START */
.flexbox.till-mobile{
  display: none;
}
@media only screen and (max-width: 601px){
  .till-desktop{
    display: none;
  }
  .flexbox.till-mobile{
    display: block;
  }
}
/* ADDED BY DAVID 20-10-2021 START */