.financialpage h2{
    font-size: 4.5vw;
}
/*Financial section start*/
.financialpage.boilers-section{
    padding-top: var(--left-right-padding);
    margin-top: calc(var(--left-right-padding) / -2);
    margin-bottom: calc(var(--left-right-padding) / 2);
}
.financialpage h1{
    font-size: 6vw;
}
.financialpage p{
    font-size: 1.6vw;
    color: #626469;
}
.boilers-section .img-part{
    text-align: right;
}
.boilers-section .img-part img{
    margin-top: -40%;
    max-width: 86%;
}
.financialpage .first_sub_heading {
    font-size: 2.2vw;
    line-height: 1.25;
    color: #626469;
}
.financialpage .pl-4{
    padding-left: 3vw;
}

.center-section {
    align-self: center;
}
.representative-part {
    background-color: #f3f3f5;
    border-radius: 1.5vw;
    padding: 2vw 1vw;
}

.representative-part .heading {
    font-size: 2.2vw;
    line-height: 1.25;
    font-weight: 700;
    margin-bottom: 0px;
}

.representative-part .sub_heading {
    font-size: 1.6vw;
    font-style: normal;
    font-weight: 100;
    color: unset;
    padding: 0.2vw 0vw;
    margin-bottom: 0.5vw;
}

.representative-part .payment_count {
    border-radius: 15px;
    background-color: #ffffff;
    border: 1px solid #00a5cf;
    padding: 2vw;
}

.payment_count {
    font-size: 1.6vw;
    color: #626469;
}

.payment_count hr {
    margin: 0px;
}
.payment_count .label {
    font-weight: 600;
}
/*Financial section end*/

/* How it's work section Start */
.financial-how-it-works .saving-benefits {
    padding-bottom: 5vw;
}
.financial-how-it-works .savings-title{
    line-height: 1;
}

.financial-how-it-works img {
    max-width: 20vw;
}

.financial-how-it-works .laptop-div {
    align-self: flex-end;
}

/* How it's work section End  */

/* Vindigo section start */
.vindigo-section {
    background: url("./../../../assets/images/financialpage/background-3.png");
    clip-path: polygon(0 0, 0 0, 100% 0, 100% 85%, 50% 100%, 0 85%);
    -webkit-clip-path: polygon(0 0, 0 0, 100% 0, 100% 87.5%, 50% 100%, 0 87.5%);
    clip-path: polygon(0 0, 0 0, 100% 0, 100% 87.5%, 50% 100%, 0 87.5%);
    padding: 5vw 4vw;
    background-size: cover;
}

.vindigo-section .heading{
    padding-bottom: 7vw;
}

.vindigo-section h1 {
    font-size: 3.8vw;
    color: #FFF;
}

.vindigoLogo {
    padding-left: 8vw;
    padding-right: 3vw;
}

.vindigo-section .left-text {
    padding-top: 2vw;
}

.vindigo-section .right-text{
    padding-top: 5vw;
}

.vindigo-section .left-text p {
    color: #FFF;
    font-size: 1.5vw;
}

.vindigo-section .btn-div{
    padding-top: 4vw;
}

.vindigo-section .pl-0 {
    padding-left: 0vw;
}

.vindigo-section .pr-0{
    padding-right: 0vw;
}
/* Vindigo section end*/

/* eligible section start */
.eligible-section {
    background: #f3f3f5 ;
    clip-path: polygon(0 0, 0 0, 100% 0, 100% 85%, 50% 100%, 0 85%);
    -webkit-clip-path: polygon(0 0, 0 0, 100% 0, 100% 87.5%, 50% 100%, 0 87.5%);
    clip-path: polygon(0 0, 0 0, 100% 0, 100% 87.5%, 50% 100%, 0 87.5%);
    padding: 5vw 4vw;
}

.eligible-section .heading{
    padding-bottom: 7vw;
}

.eligible-section h1 {
    font-size: 3.8vw;
}

.eligible-section .left-text {
    padding-top: 2vw;
}

.eligible-section .left-text p {
    font-size: 1.6vw;
}

.eligible-section .right-image {
    position: absolute;
}

.eligible-section .right-image img {
    width: 36vw;
    padding-top: 14vw;
}

.eligible-section .btn-div{
    padding-top: 2vw;
}
/* eligible section end */

/* CancellationsRefunds-section START */
.CancellationsRefunds-section {
    padding: 5vw 4vw;
}

.CancellationsRefunds-section h1 {
    font-size: 4.7vw;
}

.CancellationsRefunds-section p {
    font-size: 1.58vw;
}

.CancellationsRefunds-section a {
    text-decoration: none;
    color: var(--color-skyblue);
}

.CancellationsRefunds-section a:hover {
    color:var(--color-skyblue);
}
/* CancellationsRefunds-section END */
/* @media only screen and (max-width:767px){
    .financial-how-it-works{
        padding-bottom: 3vw;
    }

    .financial-how-it-works .laptop-div img{
        padding-bottom: 2vw;
        padding-top: 2vw;
    }
} */
@media only screen and (max-width:767px) {
    .financialpage h1 {
        font-size: 12vw;
    }

    .financialpage .first_sub_heading, .representative-part .heading{
        font-size: 5.2vw;
    }

    .representative-part .sub_heading{
        font-size: 4.6vw;
    }

    .payment_count {
        font-size: 4.6vw;
    }

    .financial-how-it-works .laptop-div img{
        padding-bottom: 2vw;
        padding-top: 2vw;
    }

    .vindigo-section h1 {
        font-size: 5.8vw;
    }

    .vindigo-section .left-text p{
        font-size: 4.6vw;
    }

    .vindigo-section .quote-button > button, .eligible-section .quote-button > button {
        font-size: 4.6vw;
    }

    .vindigo-section .btn-div, .eligible-section .btn-div {
        text-align: center;
        padding-bottom: 4vw;
    }

    .vindigo-section .right-text {
        padding-bottom: 14vw;
    }

    .eligible-section h1{
        font-size: 5.8vw;
    }

    .eligible-section .left-text p {
        font-size: 4.6vw;
    }

    .CancellationsRefunds-section h1 {
        font-size: 6.7vw;
    }

    .CancellationsRefunds-section p {
        font-size: 4.58vw;
    }

    .financialpage p {
        font-size: 4vw;
    }

    .vindigo-section {
        clip-path: polygon(0 0, 0 0, 100% 0, 100% 95%, 50% 100%, 0 95%);
        -webkit-clip-path: polygon(0 0, 0 0, 100% 0, 100% 95%, 50% 100%, 0 95%);
        clip-path: polygon(0 0, 0 0, 100% 0, 100% 95%, 50% 100%, 0 95%);
        padding: 5vw 4vw;
    }

    .vindigo-section .heading{
        padding-bottom: 0vw;
    }

    .eligible-section {
        clip-path: polygon(0 0, 0 0, 100% 0, 100% 95%, 50% 100%, 0 95%);
        -webkit-clip-path: polygon(0 0, 0 0, 100% 0, 100% 95%, 50% 100%, 0 95%);
        clip-path: polygon(0 0, 0 0, 100% 0, 100% 95%, 50% 100%, 0 95%);
        padding: 5vw 4vw;
    }

    .eligible-section .right-image{
        position: relative;
        text-align: center;
    }
}
