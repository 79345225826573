/**/
.startpage.find-section{
    padding-top: 14%;
    padding-bottom: 14%;
    background: url("../../../assets/images/startsurvey/Boiler-background.png") no-repeat center;
    background-size: 100% 100%;
    color: white;
    margin-top: -8%;
    /* margin-bottom: -8%; */
}
.startpage.find-section h1{
    font-size: 4.5vw;
}
.startpage.find-section p{
    font-size: 1.435vw;
}
.startpage.find-section form{
    width: 85%;
    margin-top: 3vw;
}
.startpage.find-section form label{
    font-family: 'Museo 700';
    font-size: 2.2vw;
    margin-bottom: 1vw;
}
.startpage.find-section form .form-control{
    border-radius: 12px;
    flex: 1.5;
    margin-right: 1vw;
    font-size: 1.8vw;
    border: none;
}
.startpage.find-section form button{
    flex: 1;
    border-radius: 12px;
    background: var(--color-skyblue);
    border: none;
    color: white;
    font-family: 'Museo 700';
    font-size: 1.95vw;
}
/**/
@media only screen and (max-width:767px) {
    .startpage.find-section{
        background-image: url("../../../assets/images/startsurvey/boiler-dark-extended.png");
        text-align: center;
        padding-top: 75%;
        /* margin-top: -20%; */
        margin-top: -22%;
        padding-bottom: 5%;
        /* ADDED BY DAVID FOR UNSQUASH IMAGE OF BOILER 18-10-2021 START */
        background-color: #000;
        background-size: auto 83%;
        /* ADDED BY DAVID FOR UNSQUASH IMAGE OF BOILER 18-10-2021 END */
    }
    .startpage.find-section h1 {
        font-size: 10vw;
        /* margin-top: 5.8vw; Added by David 21-10-2021 */
    }
    .startpage.find-section p {
        font-size: 3.25vw;
    }
    .startpage.find-section form{
        width: 60%;
        margin: 0 auto
    }
    .startpage.find-section form label {
        font-size: 3.5vw;
    }
    .startpage.find-section form .form-control{
        font-size: 2.8vw;
        border-radius: 6px;
    }
    .startpage.find-section form button{
        font-size: 3vw;
        border-radius: 6px;
        display: inline;
    }
}

/* Changes By Rohan start*/
@media only screen and (max-width: 601px){
    .get-started-survey {
        transform: scale(1.5);
    }
}
/* Changes By Rohan end*/

/* ADDED BY DAVID JCASP DEVELOPER 12-10-2021 START */
@media only screen and (max-width: 580px){
    .control-label {
        font-size: 1rem !important;
    }
}
@media only screen and (max-width: 413px){
    .control-label {
        font-size: 0.95rem !important;
    }
}
@media only screen and (max-width: 392px){
    .control-label {
        font-size: 0.93rem !important;
    }
}
@media only screen and (max-width: 384px){
    .control-label {
        font-size: 0.91rem !important;
    }
}
@media only screen and (max-width: 375px){
    .control-label {
        font-size: 0.9rem !important;
    }
}
@media only screen and (max-width: 371px){
    .control-label {
        font-size: 0.88rem !important;
    }
}
@media only screen and (max-width: 363px){
    .control-label {
        font-size: 0.86rem !important;
    }
}
@media only screen and (max-width: 355px){
    .control-label {
        font-size: 0.84rem !important;
    }
}
@media only screen and (max-width: 347px){
    .control-label {
        font-size: 0.82rem !important;
    }
}
@media only screen and (max-width: 347px){
    .control-label {
        font-size: 0.80rem !important;
    }
}
@media only screen and (max-width: 337px){
.startpage.find-section form button{
    font-size: 2.8vw;
    }
}
@media only screen and (max-width: 330px){
    .control-label {
        font-size: 0.79rem !important;
    }
}
@media only screen and (max-width: 330px){
    .control-label {
        font-size: 0.77rem !important;
    }
}
@media only screen and (max-width: 320px){
    .control-label {
        font-size: 0.75rem !important;
    }
}
@media only screen and (max-width: 310px){
    .control-label {
        font-size: 0.73rem !important;
    }
@media only screen and (max-width: 301px){
        .control-label {
            font-size: 0.62rem !important;
        }
    }
}
/* ADDED BY DAVID JCASP DEVELOPER 12-10-2021 END */