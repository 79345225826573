/* Header */

.header {
    width: 100%;
    height: 100vh;
    min-height: 700px;
    clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 50% 100%, 0 85%);
}



/* Ken Burns Effect */

.kb-wrap {
    overflow: hidden;
    position: relative;
}

.kb-wrap img {
    width: 100%;
    animation: move 40s ease infinite;
    /* Add infinite to loop. */

    -ms-animation: move 40s ease infinite;
    -webkit-animation: move 40s ease infinite;
    -o-animation: move 40s ease infinite;
    -moz-animation: move 40s ease infinite;
    position: absolute;
}
.top-section.homepage{
    animation: movebg 30s ease infinite;
    -ms-animation: movebg 30s ease infinite;
    -webkit-animation: movebg 30s ease infinite;
    -o-animation: movebg 30s ease infinite;
    -moz-animation: movebg 30s ease infinite;
    animation-timing-function: linear;
}
@-webkit-keyframes move {
    0% {
        -webkit-transform-origin: bottom;
        -moz-transform-origin: bottom;
        -ms-transform-origin: bottom;
        -o-transform-origin: bottom;
        transform-origin: bottom;
        transform: scale(1.0);
        -ms-transform: scale(1.0);
        /* IE 9 */

        -webkit-transform: scale(1.0);
        /* Safari and Chrome */

        -o-transform: scale(1.0);
        /* Opera */

        -moz-transform: scale(1.0);
        /* Firefox */
    }

    100% {
        transform: scale(1.4);
        -ms-transform: scale(1.4);
        /* IE 9 */

        -webkit-transform: scale(1.4);
        /* Safari and Chrome */

        -o-transform: scale(1.4);
        /* Opera */

        -moz-transform: scale(1.4);
        /* Firefox */
    }
}
@keyframes movebg {
    0% {
        -webkit-transform-origin: bottom;
        -moz-transform-origin: bottom;
        -ms-transform-origin: bottom;
        -o-transform-origin: bottom;
        transform-origin: bottom;
        background-size: 100% 100%;
    }

    100% {
        background-size: 140% 140%;
    }
}

/* TOP SECTION */

.start-button {
    width: 75px;
    height: 75px;
    padding: 10px;
    margin: 0 auto;
    background-color: #2A2723;
    border-radius: 50%;
    transition: 0.3s ease;
    cursor: pointer;
    /*box-shadow: 0 0 10px 5px rgba(95, 149, 171, 0.5);*/
    box-shadow: 0 0 10px 2px rgb(179 255 189 / 50%);
    
}
.trustpilot-mobile{
    display: none;
}
.start-button:hover {
    background-color: #91BD1F;
    box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.9);
}
.button-section{
    left: 50%;
    bottom: -0vw;
    transform: translate(-50%, -50%);
}
.button-section > span{
    color: #ffffff;
    text-transform: uppercase;
    font-size: 3vw;
    font-family: 'Museo 700';
}

.image-section {
    top: 15%;
    left:0%;
    width: 100%;
}

.image-section img {
    width: 100%;
}

@media only screen and (min-width: 2500px) {
    .image-section {
        top: 10%;
    }

    .start-button{
        width: 100px;
        height: 100px;
    }
}

@media only screen and (max-width: 1600px){
    .button-section{
        bottom: -3vw;
    }
}

@media only screen and (max-width: 1366px){
    .button-section{
        bottom: -5vw;
        margin-bottom: 1.6rem;
    }
}

.text-section{
    top: 50%;
    /* left: 50%;
    transform: translate(-50%, -50%); */
    left: 30%;
    transform: translate(-20%, -50%);
    text-align: center;
    color: white;
}
.text-section > .section-heading{
    /* font-size: 5.8vw; */
    font-size: 5.7vw;
    font-family: 'Museo 300' !important;
    margin-bottom: 15px;
}
.text-section > span{
    font-family: 'Museo 300' !important;
    font-size: 2.7vw;
    margin:0vw 2.5vw;
}
.text-section > span > strong {
    font-weight: 900;
    font-size: 3vw;
}
.text-section > .bold-heading {
    font-size: 2.7vw;
    font-weight: 1000;
    /* padding-top: 1vw; */
}
.top-section{
    background: url("./../../../assets/images/home-fibre-optic.jpg");
    clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 50% 100%, 0 90%);
    width: 100%;
    height: 100vh;
    background-position: center;
    min-height: 650px;
    background-size: 100%;
    margin-top: -105px;
}
/* TOP SECTION END*/
/*SAVINGS SECTION*/
.saving-section-mobile{
    display: none;
}
.saving-section{
    background: url("./../../../assets/images/boiler-dark.jpg");
    text-align: center;
    color: white;
    padding-top: 55vw;
    padding-bottom: 5vw;
    background-size: 100% 100%;
    background-position: top center;
    background-repeat: no-repeat;
    margin-top: -13%;
}
.icon-div{
    width: 16.66%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    color: white;
}
.icon-div > img{
    max-width: 50%;
}
.icon-div > p{
    font-size: 1.5vw;
    margin-top: 1rem;
}
.savings-title{
    font-size: 5vw;
    padding: 3vw 12vw;
}
.saving-section .savings-title{
    padding: 3vw 4.1vw;
    line-height: 5vw;
}
.how-it-works .savings-title{
    line-height: 1;
}

/* .laptop-div img{
    width: 321px;
    height: 213px;
} */

/*SAVINGS SECTION END*/
/*How it works*/
/* .laptop-div > img{
    max-width: 85%;
} */
.laptop-div > p{
    font-size: 2.4vw;
    margin-bottom: 0;
    line-height: 1.4;
    margin-top: 1.5rem;
}
.how-it-works{
    background: url("./../../../assets/images/footer-fibre-optic.jpg");
    color: white;
    text-align: center;
    background-size: cover;
}
.spacer{
    padding-top: 5vw;
    padding-bottom: 5vw;
}

/* Changes By Rohan start*/
.laptop-desk {
    width: 80%;
}
/* Changes By Rohan end/
/*How it works end*/

/*Best fitting*/
.best-fitting{
    padding-top: 70px;
    padding-bottom: 70px;
}
.boiler-image img{
    max-width: 80%;
}
.best-fit-title h1{
    font-size: 3.9vw;
}
.best-fit-content > p{
    font-size: 2.35vw;
}
.best-fit-bottom{
    align-items: center;
    margin-top: 2rem;
}
.best-fit-bottom .covid-icon{
    /* max-width: 85px; */
    max-width: 5.5vw;
    margin-right: 15px;
}
.best-fit-bottom .safe-install{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.best-fit-bottom .safe-install > p{
    margin-bottom: 0;
    font-size: 1.9vw;
}
.best-fit-bottom .safe-install > a{
    text-decoration: none;
    font-size: 1.5vw;
    color: var(--color-green);
    font-weight: 800;
}
.best-fit-content > p:first-of-type, .best-fit-content > p:last-of-type{
    margin-top: 1.3rem;
}
/*Best fitting end*/
/*Price section*/
/* .price-guarantee{
    background: url('./../../../assets/images/grey-gradient.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 3.5vw;
}*/
.section-title{
    font-size: 4.45vw;
}/*
.price-images{
    position: relative;
}
.price-images > img:first-of-type{
    width: 60%;
    position: absolute;
    bottom: 0;
}
.price-images > img:last-of-type{
    width: 35%;
    position: absolute;
    right: 0;
    bottom: 10%;
}
.price-content p{
    font-size: 2.65vw;
    line-height: 1;
}
.price-button{
    padding: 30px 0 50px;
} */
/*Price section end*/
/*flexible ways*/
.flexible-ways{
    padding-top: 40px;
    padding-bottom: 60px;
}
.flexible-ways .section-title{
    margin-bottom: 40px;
}
.flexi-started{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.flexi-icon > img{
    width: 70%;
}
.flexi-text{
    font-size: 2.5vw;
    margin: 20px 0 10px;
    line-height: 1.3;
}
.flexi-button button{
    padding: 0px 30px !important;
    font-size: 28px !important;
    font-weight: 800 !important;
}
/*flexible ways end*/
/*for-future section*/
.for-future{
    background: url("./../../../assets/images/green-iq-close-up-crop.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-clip-path: polygon(0px 0px, 100% 0px, 100% 80%, 15% 100%, 0px 80%);
    clip-path: polygon(0px 0px, 100% 0px, 100% 80%, 15% 100%, 0px 80%);
    padding-bottom: 100px;
    padding-top: 50px;
}
.for-future p{
    font-size: 2.7vw;
    line-height: 1.2;
    margin: 1rem 0 2rem;
}
.future-content{
    padding-top: 50px;
    padding-bottom: 100px;
}
.future-content p{
    font-size: 2.7vw;
    line-height: 1.2;
}
/*for-future section end*/
/*smart tech section*/
.chevron{
    -webkit-clip-path: polygon(0% 0%, 15% 17%, 100% 0%, 100% 85%, 85% 100%, 0% 85%);
    clip-path:polygon(0% 0%, 15% 17%, 100% 0%, 100% 85%, 85% 100%, 0% 85%);
}
.smart-tech{
    background: url("./../../../assets/images/home-fibre-optic.jpg");
    background-size: 125%;
    background-position: top center;
    background-repeat: no-repeat;
    color: white;
    margin-top: -7%;
    padding-top: 7%;
    padding-bottom: 7%;
}
.smart-tech .section-title{
    font-size: 4.4vw;
}
.smart-tech .future-content{
    padding-left: 50px;
    padding-top: 100px;
    padding-bottom: 100px;
}
.smart-tech .future-content p{
    font-size: 2.55vw;
    margin-bottom: 40px;
    margin-top: 20px;
}
.smart-tech .row .col-md-6:last-of-type{
    padding-top: 60px;
    padding-bottom: 60px;
    text-align: right;
}
/*smart tech section end*/
/*keep it clean*/
.keep-it-clean{
    background: url('./../../../assets/images/grey-gradient.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-left: 7%;
}
.keep-it-clean .future-content p {
    font-size: 2.31vw;
    line-height: 1.2;
    margin-top: 20px;
    margin-bottom: 40px;
}
.keep-it-clean .col-md-6:first-of-type{
    position: relative;
}
.keep-it-clean .col-md-6:first-of-type img{
    position: absolute;
    bottom: 0;
}
.chevron-reverse{
    -webkit-clip-path:polygon(0% 3%, 85% 21%, 100% 3%, 100% 85%, 15% 100%, 0% 85%);
    clip-path:polygon(0% 3%, 85% 21%, 100% 3%, 100% 85%, 15% 100%, 0% 85%);
    margin-top: -7%;
}
.keep-it-clean .row .col-md-6:first-of-type{
    padding-top: 100px;
    padding-left: 3%;
}
.keep-it-clean .row .col-md-6 img{
    max-width: 70%;
}
.keep-it-clean .row .col-md-6:last-of-type{
    padding-top: 150px;
}
/*keep it clean end*/
/*faq section*/
.faq-section{
    padding-top: 3%;
    padding-bottom: 3%;
}
.faq-section .section-title{
    font-size: 4vw;
}
.faq-section p{
    font-size: 2.3vw;
    color: grey;
    line-height: 1.4;
}
/*faq section end*/
/*trustpilot section*/
.trustpilot .col-md-9{
    padding-right: 40px;
}
/*trustpilot section end*/
@media only screen and (min-width: 1440px) {
    .price-guarantee{
        padding-top: 8vw;
    }
    .flexi-button button{
        padding: 0% max(2%, 20px) !important;
        font-size: 2vw !important;
    }
    .chevron{
        clip-path: polygon(0% 0%, 15% 17%, 100% 0%, 100% 85%, 85% 100%, 0% 85%);
    }
    .smart-tech{
        margin-top: -6vw;
    }
    .chevron-reverse{
        clip-path: polygon(0% 0%, 85% 17%, 100% 0%, 100% 85%, 15% 100%, 0% 85%);
        margin-top: -5vw;
    }
}
@media only screen and (min-width: 2000px) {
    .chevron{
        clip-path: polygon(0% 0%, 15% 15%, 100% 0%, 100% 85%, 85% 100%, 0% 85%);
    }
    .smart-tech{
        margin-top: -5vw;
    }
    .chevron-reverse{
        clip-path: polygon(0% 0%, 85% 20%, 100% 0%, 100% 85%, 15% 100%, 0% 85%);
        margin-top: -5vw;
    }
    .smart-tech.chevron .col-md-6 > img{
        width: 85%;
    }
    .keep-it-clean .row .col-md-6 img {
        max-width: 100%;
        width: 55%;
    }
}
@media only screen and (max-width: 1024px) {

    .image-section {
        top: 15vw;
    }

    .chevron-reverse, .smart-tech{
        margin-top: -8.5%;
    }
    .best-fit-bottom .safe-install > p{
        font-size: 1.45vw;
    }
    .best-fit-bottom .col-md-5, .best-fit-bottom .col-md-7{
        /* width: 50%; */
    }
    .savings-title{
        font-size: 48px;
    }
    .top-section{
        height: auto;
        background-size: cover;
    }
}
@media only screen and (max-width: 960px){
    .saving-section{
        padding-top: 60vw;
    }
    .saving-section .savings-title{
        padding: 3vw;
    }
    .section-title{
        font-size: 4.4vw;
    }
    .quote-button > button{
        /* padding: 5% max(2%, 20px); */
        padding: 2% max(2%, 20px);
        font-size: 2vw;
    }
    .flexi-button button{
        /* padding: 5% max(2%, 20px) !important;
        font-size: 2.5vw !important; */
        padding: 2% max(2%, 20px) !important;
        font-size: 2vw !important;

    }
    .smart-tech .section-title{
        font-size: 4vw;
    }
    .faq-section .quote-button button{
        padding: 2% max(2%, 20px);
    }
}
@media only screen and (max-width: 768px){
    .savings-title {
        font-size: 34px;
    }
    .trustpilot .col-md-3{
        text-align: center;
    }
    .trustpilot .col-md-9{
        padding-left: 40px;
    }
    .boiler-image{
        text-align: center;
        margin-bottom: 20px;
    }
    .boiler-image img {
        max-width: 100%;
    }
    .for-future .col-md-6{
        width: 50%;
    }
    .chevron-reverse, .smart-tech{
        margin-top: -10.5%;
    }
    .smart-tech .future-content{
        padding-left: 0;
        width: 50%;
    }
    .smart-tech .row .col-md-6:last-of-type{
        width: 50%;
    }
    .chevron {
        -webkit-clip-path: polygon(0% 0%, 15% 20%, 100% 0%, 100% 85%, 85% 100%, 0% 85%);
        clip-path: polygon(0% 0%, 15% 20%, 100% 0%, 100% 85%, 85% 100%, 0% 85%);
    }
    .keep-it-clean.chevron-reverse{
        margin-top: -10%;
        -webkit-clip-path: polygon(0% 3.5%, 85% 20%, 100% 3.5%, 100% 85%, 15% 100%, 0% 85%);
        clip-path: polygon(0% 3.5%, 85% 20%, 100% 3.5%, 100% 85%, 15% 100%, 0% 85%);
    }
    .keep-it-clean .row .col-md-6 img{
        max-width: 80%;
    }
    .quote-button > button{
        font-size: 22px;
        padding: 2% max(2%, 20px);
    }
    .keep-it-clean .row .col-md-6:first-of-type {
        padding-left: 0;
        width: 50%;
    }
    .keep-it-clean .row .col-md-6:last-of-type {
        width: 50%;
    }
    .best-fit-bottom .safe-install > p{
        font-size: 1.34vw;
    }
    .best-fit-bottom .covid-icon{
        max-width: 65px;
    }
    .saving-section .savings-title {
        padding: 3vw 9vw;
    }
}
@media only screen and (max-width:767px){
    .top-section {
        min-height: calc(100vh - 107px);
        clip-path: unset;
        margin-top: -1px;
        overflow: hidden;
    }
    .text-section{
        top: 33vw;
        padding-left: var(--left-right-padding-mobile);
        padding-right: var(--left-right-padding-mobile);
        width: 100%;
    }
    /* .text-section > .section-heading{
        font-size: 14vw;
        margin-bottom: 10px;
        line-height: 14vw;
    } */
    /* ADDED BY DAVID 18-10-2021 FOR RESPONSIVENESS  START  */
    .text-section > .section-heading{
        font-size: 7vw;
        /* margin-bottom: 20rem;
        line-height: 8vw; */
        margin-top: -24rem;
    }
    
    .text-section > span{
        /* font-size: 6vw; */
        font-size: 4vw;
    }
    /* ADDED BY DAVID 18-10-2021 FOR RESPONSIVENESS  END */
    .start-button{
        width: 60px;
        height: 60px;
        margin-bottom: -10px;
    }
    .button-section > span{
        font-size: 28px;
    }
    .trustpilot-mobile{
        display: block;
        /* margin-top: 8vw; */
        margin-top: 5vw; /* change by David 20-10-2021 */
    }
    .button-section{
        bottom: -18vw;
    }

    /**/
    .saving-section{
        display: none;
    }
    .saving-section-mobile{
        display: block;
        position: relative;
        overflow: hidden;
        height: 100vh;
        color: white;
        background: black;
    }
    .saving-section-mobile > img{
        /* height: 100%;comment by David */
        /* top: -12%; Comment by David to unsquish the image of the boiler in home page*/
        position: absolute;
        margin-left: -5%;
        margin-right: -5%;
        z-index: 0;
        transform: scale(1.3);
    }
    .saving-section-mobile .saving-benefits{
        z-index: 1;
        position: relative;
        margin-top: 52vw;
        flex-wrap: wrap;
    }
    .saving-section-mobile .icon-div {
        width: 33.33%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        color: white;
    }
    .saving-section-mobile .icon-div > img {
        max-width: 36%;
    }
    .saving-section-mobile .icon-div > p {
        font-size: 3vw;
        line-height: 1.1;
        margin-top: 0.5rem;
        margin-bottom: 2rem;
    }
    .saving-section-mobile .savings-title{
        text-align: center;
        font-size: 5vw;
        z-index: 1;
        line-height: 1.25;
        position: relative;
    }
    .saving-section-mobile .quote-button{
        text-align: center;
        z-index: 1;
        position: relative;
    }
    .saving-section-mobile .quote-button > button {
        padding: 0.5% max(2%, 20px);
        font-size: 4.5vw;
        margin: 15px 0;
    }
    .saving-section-mobile .saving-lower-part{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 3vw;
        padding-left: var(--left-right-padding-mobile);
        padding-right: var(--left-right-padding-mobile);
    }
    /**/
    .how-it-works{
        padding-bottom: 3vw;
    }
    .savings-title {
        font-size: 11vw;
        padding: 3.5vw 0 1vw;
    }
    .saving-benefits.flexbox.flexbox-space-between{
        flex-wrap: wrap;
    }
    .laptop-div.text-center {
        width: 100%;
    }
    .laptop-div img {
        /* max-width: 45%; */
        max-width: 100%;
        margin-top: 10vw;
        margin-bottom: 1vw;
    }
    .laptop-div > p {
        /* font-size: 3.4vw; */
        font-size: 4.4vw;
        margin-top: 10px;
        margin-bottom: 1.5vw;
    }
    .how-it-works .spacer{
        display: none;
    }
    .best-fitting{
        /* min-height: 100vh; */
    }
    .boiler-image.col-md-4, .best-fit-content{
        text-align: center;
    }
    .boiler-image img {
        max-height: 50vh;
        /* max-width: 90%; */
        min-height: 250px;
        margin: 3vw auto 0;
    }
    .best-fit-title h1 {
        font-size: 7vw;
        text-align: center;
        padding: 0 18vw;
        line-height: 1.1;
    }
    .best-fit-content > p {
        font-size: 4.2vw;
        line-height: 1.3;
        margin-top: 0;
    }
    .best-fit-bottom{
        flex-wrap: wrap;
    }
    .best-fit-bottom .col-md-5, .best-fit-bottom .col-md-7 {
        width: 100%;
        text-align: center;
        justify-content: center;
    }
    .best-fitting .quote-button > button {
        padding: 0.5% max(2%, 20px);
        font-size: 4.5vw;
        margin-top: 3vw;
    }
    .best-fit-bottom .col-md-7{
        margin-top: 5vw;
    }
    .best-fit-bottom .safe-install > p {
        font-size: 3.34vw;
    }
    .best-fit-bottom .safe-install > a {
        font-size: 3vw;
        text-align: left;
    }
    .best-fitting{
        padding-top: 3vw;
        padding-bottom: 3vw;
    }
    /* Faq Section for mobile start */
    .faq-section{
        padding-top: 6vw;
        padding-bottom: 6vw;
        min-height: 40vh;
    } 

    .faq-section .section-title {
        font-size: 12vw;
    }

    .faq-section p {
        font-size: 3.2vw;
        padding-top: 2vw;
    }

    .faq-section .quote-button button {
        padding: 1% max(1%, 10px);
        font-size: 5vw;
        margin-bottom: 1rem;
    }
    /* Faq Section for mobile start */
    /*Price guarantee start
    .price-guarantee{
        min-height: 100vh;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        background: #5F99AF;
        overflow-x: hidden;
    }
    .price-content{
        order: 2;
        min-height: 46vh;
        padding-left: var(--left-right-padding-mobile) !important;
        padding-right: var(--left-right-padding-mobile) !important;
        padding-top: 2vw;
        padding-bottom: 4vw;
        color: #ffffff;
        text-align: center;
    }
    .price-images{
        position: relative;
        height: max(54vh, 300px);
        order: 1;
        padding-left: 5%;
        background-color: rgb(59,136,158);
        padding-right: 5%;
        margin: 0 auto;
        clip-path: polygon(50% 100%, 100% 90%, 100% 0, 0 0, 0 90%);
    }
    .price-images > img:nth-child(2){
        width: auto;
        position: absolute;
        bottom: 0;
        left: 50%;
        right: 0;
        transform: translateX(-50%);
    }
    .price-guarantee .section-title {
        font-size: 11.5vw;
    }
    .price-content p {
        font-size: 5.7vw;
        padding-left: var(--left-right-padding-mobile);
        padding-right: var(--left-right-padding-mobile);
    }
     .price-guarantee .quote-button > button {
        padding: 0.5% max(3%, 30px);
        background: #ffffff !important;
        font-size: 4.5vw;
        color: var(--color-skyblue) !important;
    } 
    .price-guarantee .price-button{
        padding: 1vw 0 0 0;
    }
    /*Price guarantee end*/
    /*Flexible ways start*/
     .flexible-ways{
        /* min-height: 100vh; */
        position: relative;
        padding-bottom: 0;
    }
    .flexible-ways .section-title {
        font-size: 13vw;
        line-height: 1.1;
        padding-left: 10%;
        padding-right: 10%;
    }
    .flexible-ways > .row{
        padding-left: 10%;
        padding-right: 10%;
    }
    .flexi-started {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 4vw !important;
    }
    .flexi-started .flexi-button{
        display: none;
    }
    .flexi-text {
        font-size: 5.5vw;
        margin: 0;
    }
    .flexi-icon.text-center, .flexi-text.text-center{
        text-align: left !important;
    }
    .flexi-icon.text-center {
        width: min(40%, 125px);
    }
    .flexible-ways .quote-btn.show-mobile{
        /* background: #08A2C9; */
        background: linear-gradient(90deg, rgba(8,162,201,1) 0%, rgba(8,162,201,1) 51%, rgba(8,162,201,0.6502976190476191) 100%);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-left: -10%;
        margin-right: -10%;
        text-align: center;
        margin-top: 50px;
        width: 120%;
        height: max(20vh, 180px);
        bottom: 0;
        -webkit-clip-path: polygon(50% 15%, 100% 0, 100% 100%, 0 100%, 0 0);
        clip-path: polygon(50% 15%, 100% 0, 100% 100%, 0 100%, 0 0);
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 3vw;
    }
    .flexible-ways .quote-button > button {
        padding: 0.5% max(3%, 30px);
        background: #ffffff !important;
        font-size: 4.5vw;
        color: var(--color-skyblue) !important;
    } 
    /*Flexible ways end*/
    /*For future start*/
    .mobile-future-img{
        height: 50vh;
        background: url("../../../assets/images/green-iq-close-up-cc.jpg");
        background-repeat: no-repeat;
        background-size: 100% 140%;
        background-position: center;
        padding: 0;
        clip-path: polygon(50% 100%, 100% 90%, 100% 0, 0 0, 0 90%);
        overflow:hidden;
    }
    .for-future{
        padding: 0;
        overflow-x: hidden;
        background: none;
        clip-path: none;
    }
    .future-content{
        padding-left: var(--left-right-padding-mobile) !important;
        padding-right: var(--left-right-padding-mobile) !important;
        text-align: center;
        line-height: 1.2;
        padding-bottom: 0;
    }
    .for-future .section-title{
        font-size: 11.5vw;
        padding-left: 5%;
        padding-right: 5%;
    }
    .future-content p{
        font-size: 5.7vw;
        /* line-height: 4vh; */
        padding-left: var(--left-right-padding-mobile);
        padding-right: var(--left-right-padding-mobile);
    }
    .for-future .quote-button > button{
        padding: 0.5% max(3%, 30px);
        font-size: 4.5vw;
        line-height: 1.5;
    }
    /*For future end*/
    /*Smart tech start*/
    .smart-tech{
        clip-path: none;
        margin-top: 0;
        /* min-height: 100vh; */
        padding: 0;
        background: none;
        color: inherit;
    }
    .smart-tech .future-content{
        padding-left: var(--left-right-padding-mobile) !important;
        padding-right: var(--left-right-padding-mobile) !important;
        text-align: center;
        line-height: 1.2;
        width: 100%;
        
    }
    .smart-tech .section-title{
        font-size: 11.5vw;
        padding: 0 5%;
    }
    .smart-tech .future-content p {
        margin-bottom: 40px;
        margin-top: 20px;
        font-size: 5.65vw;
        /* line-height: 4vh; */
        padding-left: var(--left-right-padding-mobile);
        padding-right: var(--left-right-padding-mobile);
    }
    .smart-tech .quote-button > button{
        background-color: var(--color-skyblue);
        color: white;
        padding: 0.5% max(3%, 30px);
        font-size: 4.5vw;
        line-height: 1.5;
    }
    .smart-tech .row .col-md-6:last-of-type{
        width: 100%;
        background: url("../../../assets/images/home-fibre-optic.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        text-align: center;
        padding-top: 30vw;
        padding-bottom: 15vw;
        padding-left: var(--left-right-padding-mobile) !important;
        padding-right: var(--left-right-padding-mobile) !important;
        line-height: 1.2;
        clip-path: polygon(50% 0%, 100% 15%, 100% 100%, 0 100%, 0 15%);
    }
    .smart-tech .row .col-md-6:last-of-type img{
        max-width: 80%;
    }
    /*Smart tech end*/
    /*Keep it clean start*/
    .keep-it-clean{
        margin-top: 0 !important;
        padding: 0;
        clip-path: none !important;
        text-align: center;
        overflow-x: hidden;
        /* min-height: 100vh; */
        background: none;
    }
    .keep-it-clean .row .col-md-6:first-of-type {
        padding-left: 0;
        width: 100%;
        clip-path: polygon(50% 100%, 100% 90%, 100% 0, 0 0, 0 90%);
        background: url("../../../assets/images/grey-gradient.svg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .keep-it-clean .row .col-md-6 img {
        max-width: 50%;
    }
    .keep-it-clean .row .col-md-6:last-of-type{
        padding-top: 50px;
        width: 100%;
    }
    .keep-it-clean .section-title{
        font-size: 11.5vw;
    }
    .keep-it-clean .future-content p {
        margin-top: 20px;
        margin-bottom: 40px;
        font-size: 5.65vw;
        /* line-height: 4vh; */
        padding-left: var(--left-right-padding-mobile);
        padding-right: var(--left-right-padding-mobile);
    }
    .keep-it-clean .quote-button > button{
        background-color: var(--color-skyblue);
        color: white;
        padding: 0.5% max(3%, 30px);
        font-size: 4.5vw;
        line-height: 1.5;
        margin-top: 50px;
    }
    .keep-it-clean .col-md-6:first-of-type img{
        position: relative;
    }
    .keep-it-clean .row .col-md-6:first-of-type{
        padding-top: 50px;
    }
    /*Keep it clean end*/

    .image-section {
        /* top: 14vw; */
        top: 2vw; /* ADDED BY DAVID 18-10-2021 FOR RESPONSIVENESS OF AWARD BANNER */
    }

    .text-section {
        top: 60vw;
        transform: translate(-30%, -50%);
    }
}

/* Changes By Rohan start*/
@media only screen and (max-width: 601px){
    .image-section {
        transform: scale(1.9);
        transform-origin: center;
        left: 15%;
        padding-top: 0.5em;
        margin-top: 2rem;
    }

    @keyframes movebg {
        0% {
            -webkit-transform-origin: bottom;
            -moz-transform-origin: bottom;
            -ms-transform-origin: bottom;
            -o-transform-origin: bottom;
            transform-origin: bottom;
            background-size: auto 100%;
        }
    
        100% {
            background-size: auto 140%;
        }
    }
}
/* Changes By Rohan end*/

@media only screen and (max-width: 580px){

    .boiler-image img {
        margin-left: 5rem;
    }

    .button-section {
        /* bottom: -20vw; */
        bottom: -8vw; /* ADDED by David */
    }

    .image-section {
    /* Changes By Rohan start*/
        /* top: 4vw; */
      top: 11vw;
    /* Changes By Rohan end*/
    }

    .image-section img{
        /* padding: 4vh 0; */
        /* transform: translate(-100, 0); */
      position: relative;
      /* right: 45px; */
      bottom: 15px;
    }

    .text-section {
    /* Changes By Rohan start*/
    /* top: 40vw; */
      /* top: 47vw; */
      /* top: 35vw; added by David */
      /* margin-top: -5rem; */
    /* Changes By Rohan end*/
    }

    .text-section > span > strong {
        /* font-size: 5vw; */
        font-size: 4vw;
    }
    .text-section > span {
        /* font-size: 5vw; */
        font-size: 4vw;
    }
    
    .text-section > .bold-heading {
        /* font-size: 5vw; */
        font-size: 4vw;
        font-weight: 1000;
        /* padding-top: 1vw; */
    }

    .text-section > .section-heading {
        /* font-size: 10vw; */
        font-size: 7vw;
        margin-bottom: 35px;
        line-height: 1.2;
        position: relative;
        top: 1rem;
        /* margin-top: 0 !important; */
    }

    .trustpilot.full-width .row{
        justify-content: center;
    }
    .price-guarantee, .price-content{
        min-height: unset;
    }
    .price-content{
        padding-bottom: 7vw;
    }
    .price-images{
        height: max(45vh, 380px);
    }
    .keep-it-clean .quote-button > button{
        margin-top: 0;
    }
    .faq-section {
        padding-top: 10vw;
        /* padding-bottom: 10vw; */
        padding-bottom: 0;
        min-height: unset;
    }
    /* ADDED BY DAVID JCASP DEV. 12-10-2021 START */
    .faq-section .section-title {
        font-size: 1.5rem;
    }

    /* .faq-section .section-title p{
        font-size: 1.3rem;
    } */
    /* ADDED BY DAVID JCASP DEV. 12-10-2021 END */
    .smart-tech .future-content{
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

/* Changes By Rohan start*/
@media only screen and (min-aspect-ratio: 17/10) and (min-width: 1024px){
    .top-section {
        height: 56vw;
    }
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    .ios-only{
        display: block;
    }

    .android-only {
        display: none;
    }
}

@supports not (-webkit-touch-callout: none) {
    /* CSS for other than iOS devices */ 

    .ios-only{
        display: none;
    }

    .android-only {
        display: block;
    }

}
/* Changes By Rohan end*/

@media only screen and (max-width: 730px){
    .text-section > .section-heading{
        margin-top: -22rem;
    }
}
@media only screen and (max-width: 660px){
    .text-section > .section-heading{
        margin-top: -20rem;
    }
}
@media only screen and (max-width: 601px){
    .text-section > .section-heading{
        margin-top: -18rem;
        margin-top: -13rem;
    }
    /* .start-button{
        width: 80px;
        height: 80px;
    } */
}
/* @media only screen and (max-width: 601px){
    .image-section img {
       top: -9vw;
    }
    .text-section {
        top: 55vw;
     }
    .start-button{
        width: 60px;
        height: 60px;
    }
}

@media only screen and (max-width: 425px){
    .image-section {
        top: 2vw;
    }
    .text-section{
        margin-top: -5rem
    }
    
    .start-button {
        width: 60px;
        height: 60px;
    }
}
@media only screen and (max-width: 414px){
    .image-section {
        top: 2vw;
    }
}
@media only screen and (max-width: 375px){
    .image-section {
        top: -8vw;
    }
    .text-section{
        margin-top: -2rem
    }
}
@media only screen and (max-width: 360px){
    .image-section img{
        /* top: -5vw; */
    /* }
}
@media only screen and (min-aspect-ratio: 1/1) and (max-width: 767px){
    .top-section {
        min-height: unset;
        height: 760px;
    }
} */
@media only screen and (max-width: 601px) {
    .image-section {
        top: 2.5vh;
    }
    .text-section {
        top: 30vh;
    }
    
    .text-section > .section-heading{
        margin-top: 0vh;
    }
}

@media only screen and (max-width: 568px) {
    .image-section {
        top: 2.5vh;
    }
    .text-section {
        top: 30vh;
    }
    
}
/* CODED ADDED BY DAVID 19-10-2021 FOR SMALLER SCREEN REDUCE SPACE BETWEEN HEADER AND TEXT. */
@media only screen and (max-width: 425px) {
    .image-section {
        top: 0.5vh;
    }
    .text-section {
        top: 20vh;
    }
    .saving-section-mobile .saving-lower-part{
        bottom: 15vw;
    }
}
@media only screen and (max-width: 375px){
    .button-section > span {
        font-size: 20px;
    }

    .image-section {
    /* Changes By Rohan start*/
        /* top: 2vw; */
        top: -7vw;
    /* Changes By Rohan end*/
    }

    .image-section img{
        position:relative;
        bottom: 0;
    }

    .text-section {
    /* Changes By Rohan start*/
        /* top: 43vw; */
        /* top: 45vw; */
        /* top: 53vw; Change by David */

    /* Changes By Rohan end*/
    }
}
.bg-m{
    display: none;
}
@media only screen and (max-width: 519px) {
    .trustpilot.full-width.till-desktop{
        display: none;
    }
    .trustpilot.full-width.till-desktop{
        display: block;
    }
    .bg-d{
        display: none;
    }
    .bg-m{
        display: block;
    }
}
