/*TOP section start*/
.termsandcondition-section{
    padding-top: calc(var(--left-right-padding) / 2);
    padding-bottom: calc(var(--left-right-padding) / 2);
}

.termsandcondition-section h1{
    font-size: 5vw;
}
.termsandcondition-section p{
    font-size: 2.2vw;
    line-height: 1.25;
}
.termsandcondition-section .row .col-md-6:last-of-type{
    display: flex;
    justify-content: center;
    align-items: center;
}
.termsandcondition-section .row .col-md-6:last-of-type img{
    max-width: 80%;
}
/*TOP section end*/
/*Second section start*/
.termsandcondition-second-section p{
    font-size: 1.56vw;
    line-height: 1.3;
}
/*Second section end*/
@media only screen and (max-width:767px){
    .termsandcondition-section h1 {
        font-size: 12vw;
    }
    .termsandcondition-section p {
        font-size: 5.9vw;
        line-height: 1.25;
    }
    .termsandcondition-section .row .col-md-6:last-of-type {
        justify-content: flex-start;
    }
    .termsandcondition-second-section p {
        font-size: 4.56vw;
        line-height: 1.3;
        margin-bottom: 5vw;
    }
    .termsandcondition-second-section .col-md-6.img{
        display: none;
    }
    .termsandcondition-page.faq-section p{
        font-size: 4vw !important;
    }
}

.termsandcondition-second-section h2{
    font-weight: 700;
    margin-top: 4vw;
    font-size: 1.6vw;
}

.termsandcondition-second-section h3 {
    font-weight: 700;
    margin-top: 0vw;
    margin-bottom: 0vw;
    font-size: 1.6vw;
}

ul.tc_page_list {
    list-style: none;
}
ul.tc_page_list li{
    font-size: 1.56vw;
}
ul.tc_page_list li:before {
    content: "•";
    font-size: 1.56vw; 
    padding-right: .56vw;
}

@media only screen and (max-width:767px){
    .termsandcondition-second-section h2{
        margin-top: 7vw;
        font-size: 4.6vw;
    }

    .termsandcondition-second-section h3 {
        font-size: 4.6vw;
    }

    .sub_heading {
        font-size: 4.6vw;
    }

    ul.tc_page_list li{
        font-size: 4.56vw;
    }
    ul.tc_page_list li:before {
        content: "•";
        font-size: 4.56vw; 
        padding-right: 2vw;
    }
}