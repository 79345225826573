/*TOP section start*/
.blog-section{
    padding-top: calc(var(--left-right-padding) / 2);
    padding-bottom: calc(var(--left-right-padding) / 2);
}

.blog-section h1{
    font-size: 5vw;
}
.blog-section p{
    font-size: 2.2vw;
    line-height: 1.25;
}
.blog-section .row .col-md-6:last-of-type{
    display: flex;
    justify-content: center;
    align-items: center;
}
.blog-section .row .col-md-6:last-of-type img{
    max-width: 80%;
}
/*TOP section end*/

/* Filter section start */
input {
    padding: 0.5vw 1vw;
    border: 1px solid var(--color-skyblue);
    border-radius: 6px;
    width: 100%;
}

.capitalize{
    text-transform: capitalize;
}

select {
    margin-top: 0vw;
    padding: 0.5vw 2vw 0.5vw 1vw;
    font-size: 1.2vw;
    border: 1px solid var(--color-skyblue);
    background: white;
    border-radius: 6px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url("../../../assets/images/Down-scroll.svg") no-repeat right;
    background-size: 50% 50%;
    background-position-x: 130%;
}

select::-ms-expand {
    display: none;
}
/* Filter section end */

/* Blog list Start  */
.blog_category_list {
    margin-top: 3vw;
    background-color: transparent;
    
}

.blog_item > .inner_item{
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    text-decoration: none;
    color: unset;
}

.blog_item figure img{
    display: block;
    width: 100vw;
    object-fit: contain; 
}

.blog_item a{
    cursor: pointer;
}

.blog_item a > *, .blog_category > *{
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    padding-top: calc(var(--bs-gutter-x) * .5);
}

.blog_category > * {
    padding-bottom: calc(var(--bs-gutter-x) * .5);
}

.blog_category{
    background-color: var(--color-skyblue);
    color: white;
    font-size: 1.1vw;
}

.description p, .description p > * {
    font-size: 1.5vw !important;
}

.blog-filter-section > *, .pagination > * {
    font-size: 1.1vw !important; 
}

.blog_item figure {
    position: relative;
}

.blog_item figure .view-date {
    background-color: #fdc400;
    padding: 7px 10px;
    line-height: 1;
    -webkit-border-radius: 0 0 5px 5px;
    -moz-border-radius: 0 0 5px 5px;
    -ms-border-radius: 0 0 5px 5px;
    -o-border-radius: 0 0 5px 5px;
    border-radius: 0 0 5px 5px;
    top: 0px;
    right: 15px;
    color: #000;
}

.blog_item figure .view-date:before {
    content: "";
    border-style: solid;
    border-width: 0 0 10px 10px;
    border-color: transparent transparent #000 transparent;
    position: absolute;
    right: 100%;
    top: 0;
}

.blog_item figure .view-date em {
    font-size: 1.5vw;
    display: block;
    font-style: normal;
}

.blog_item .description .heading {
    min-height: 4.5vw;
    text-overflow: ellipsis;
    overflow: hidden;
}
.blog_item .description p {
    text-overflow: ellipsis;
    overflow: hidden;
    /* height: 5.2em; */
}

.blog-filter-section .mr-m-1 {
    margin-top: 0vw;
}

.heading h4 {
    font-size: 2.2vw;
}
/* Blog list end  */

/* Pagination Start */
.pagination .page-item.active .page-link {
    background-color: var(--color-skyblue);
    border-color: var(--color-skyblue);
}

.pagination .page-link {
    cursor: pointer;
    color: var(--color-skyblue);
}

.pagination .page-link:focus {
    box-shadow: none;
}

.pagination .page-link:hover {
    color: var(--color-skyblue);
}

.page-link {
    padding: 0.5vw 1.4vw;
}

/* Pagination End */

@media only screen and (min-width: 2500px){
    select{
        background-position-x: 145%;
    }
}

@media only screen and (max-width: 2500px){
    select{
        background-position-x: 145%;
    }
}

@media only screen and (max-width: 920px) {
    .description p, .description p > * , .blog_category, .blog-filter-section > *, .pagination > *{
        font-size: 3vw !important;
    }

    
    select{
        background-position-x: 140%;
    }
    
}

@media only screen and (max-width:767px){
    .blog-section h1 {
        font-size: 12vw;
    }
    .blog-section p {
        font-size: 5.9vw;
        line-height: 1.25;
    }
    .blog-section .row .col-md-6:last-of-type {
        justify-content: flex-start;
    }
    .blog-second-section p {
        font-size: 4.56vw;
        line-height: 1.3;
        margin-bottom: 5vw;
    }
    .blog-second-section .col-md-6.img{
        display: none;
    }

    .description p, .description p > * , .blog_category, .blog-filter-section > *, .pagination > *{
        font-size: 4vw !important;
    }

    .heading h4 {
        font-size: 6.2vw;
    }
}

@media only screen and (max-width:580px){
    select {
        background-position-x: 140%;
        padding: 1vw 2vw 1vw 1vw;
        font-size: 3.5vw;
    }

    .blog-filter-section .mr-m-1 {
        margin-top: 1vw;
    }
}
