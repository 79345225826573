.trustpilot-slider .flexbox.flexbox-space-between img{
    height: 25px;
}
.trustpilot-slide{
    padding-right: 20px;
}
.trustpilot .row .col-md-9{
    padding-top: 3.2%;
    padding-bottom: 2%;
}
.review-title {
    font-weight: 900;
}
p.review {
    line-height: 1.3;
    margin-bottom: 5px;
}
p.review-by {
    color: grey;
    margin-bottom: 0.7rem;
}
.slick-prev:before, .slick-next:before{
    color: black;
}
.slick-prev{
    left: -35px;
}

@media only screen and (max-width: 769px) {
    .trustpilot-slider .flexbox.flexbox-space-between img{
        height: 20px;
    }
}