/*TOP section start*/
.covid-section{
    padding-top: calc(var(--left-right-padding) / 2);
    padding-bottom: calc(var(--left-right-padding) / 2);
}

.covid-section h1{
    font-size: 5vw;
}
.covid-section p{
    font-size: 2.2vw;
    line-height: 1.25;
}
.covid-section .row .col-md-6:last-of-type{
    display: flex;
    justify-content: center;
    align-items: center;
}
.covid-section .row .col-md-6:last-of-type img{
    max-width: 80%;
}
/*TOP section end*/
/*Second section start*/
.covid-second-section p{
    font-size: 1.56vw;
    line-height: 1.3;
}
/*Second section end*/
@media only screen and (max-width:767px){
    .covid-section h1 {
        font-size: 12vw;
    }
    .covid-section p {
        font-size: 5.9vw;
        line-height: 1.25;
    }
    .covid-section .row .col-md-6:last-of-type {
        justify-content: flex-start;
    }
    .covid-second-section p {
        font-size: 4.56vw;
        line-height: 1.3;
        margin-bottom: 5vw;
    }
    .covid-second-section .col-md-6.img{
        display: none;
    }
    .covid-page.faq-section p{
        font-size: 4vw !important;
    }
}

.covid-second-section h2{
    font-weight: 700;
    margin-top: 4vw;
    font-size: 1.6vw;
}
@media only screen and (max-width:767px){
    .covid-second-section h2{
        margin-top: 7vw;
        font-size: 4.6vw;
    }

    .sub_heading {
        font-size: 4.6vw;
    }
}