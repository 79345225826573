:root{
  --left-right-padding: 10%;
  --left-right-padding-mobile: 5%;
  --color-blue: #00093D;
  --color--dark-grey: #585858;
  --color-green: #91BD1F;
  --color-skyblue:#00A6D0;
  --color-white:#FFFFFF;
  --color-darkskyblue: #00A5CF;
}

@font-face {
  font-family: 'Museo 300';
  font-style: normal;
  font-weight: normal;
  src: local('Museo 300'), url('./assets/fonts/Museo300-Regular.woff') format('woff');
}  
  
@font-face {
  font-family: 'Museo 700';
  font-style: normal;
  font-weight: normal;
  src: local('Museo 700'), url('./assets/fonts/Museo700-Regular.woff') format('woff');
}
  
* {
  font-family: 'Museo 300', 'Museo 700';
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: 'Museo 700', 'Museo 300', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.grey{
  color: var(--color--dark-grey);
}
.flexbox{
  display: flex;
} 
.flexbox-centered{
  justify-content: center;
  align-items: center;
}
.flexbox-vr-centered {
  align-items: center;
}
.flexbox-space-between{
  justify-content: space-between;
}
.relative{
  position: relative;
}
.abosolute{
  position: absolute;
}
section{
  padding-left: var(--left-right-padding);
  padding-right: var(--left-right-padding);
  overflow-x: hidden;
}
.text-center{
  text-align: center;
}
.text-green{
  color: var(--color-green) !important;
}
.bold{
  font-family: 'Museo 700';
}
img{
  max-width: 100%;
}
section.full-width{
  padding-left: 40px;
  padding-right: 40px;
}
.show-desktop{
  display: block;   
}
.show-mobile{
  display: none;
}
.color-skyblue{
  color: var(--color-skyblue);
}
.museo-700{
  font-family: 'Museo 700';
}
button{ /* added by david */
  font-weight: 700;
}
@supports (-webkit-touch-callout: none) {
  button, .restart-survey, .review-edit, .chooseDepo{
    letter-spacing: -1px;
    word-spacing: -1px;
  }
}
@media only screen and (max-device-width: 380px){
  @supports (-webkit-touch-callout: none) {
    .chooseDepo{
      letter-spacing: -1px;
      word-spacing: -1px;
    }
  }
}


@media only screen and (max-width:767px){
  section{
    padding-left: var(--left-right-padding-mobile);
    padding-right: var(--left-right-padding-mobile);
  }
  .show-desktop{
    display: none;   
  }
  .show-mobile{
    display: block;
  }
}