.boiler-detail {
    clip-path: polygon(50% 100%, 100% 95%, 100% 0, 0 0, 0 95%) !important;
}

/* ADDED BY DAVID BGCOLOR FOR EMAIL BUTTON 19-10-2021 START */
.bgColor-skyblue{
    background-color: #0d6efd;
    border-color: #0d6efd;
}
.color-skyblue.btn.btn-primary{
    color: #fff !important;
    background-color: #0d6efd;
    border-color: #0d6efd;
}
/* ADDED BY DAVID BGCOLOR FOR EMAIL BUTTON 19-10-2021 END */

.accordion-button {
    border: none !important;
    box-shadow: none !important;
    font-family: 'Museo 700';
    border-top-left-radius: unset !important;
    border-top-right-radius: unset !important;
    font-size: 2.5vw !important;
    border-radius: 10px !important;
}

.accordion-button:not(.collapsed) {
    /* color: var(--color-skyblue) !important; */
    color: black !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

.accordion-button::after {
    background-image: url("../../../assets/images/plus-64.png") !important;
    width: 1vw !important;
    height: 1vw !important;
    background-size: 1vw !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("../../../assets/images/minus-64.png") !important;
    width: 1vw !important;
    height: 1vw !important;
    background-size: 1vw !important;
}

.desc-list-wrapper .desc-list:not(:last-of-type) {
    border-bottom: 1px solid #b8b8b9;
}

.boiler-detail h2 {
    font-size: 3vw;
}

.boiler-detail>.row .col-md-8>.row {
    margin-top: 1vw;
    margin-bottom: 5vw;
}

.boiler-detail>.row .col-md-6:first-of-type>img {
    max-width: 90%;
}

.boiler-detail>.row .col-md-6:last-of-type {
    padding-top: 2vw;
    text-align: center;
}

.boiler-detail>.row .col-md-6:last-of-type>img:first-of-type {
    max-width: 75%;
}

.boiler-detail>.row .col-md-6:last-of-type>img:last-of-type {
    margin-top: 2.5vw;
}

.boiler-detail .grey-card-lower .text-part {
    width: 100%;
}

.accordion-item {
    background-color: #fff;
    border: none !important;
    margin: 2vw 0 !important;
    border-radius: 10px !important;
}

.desc-list {
    font-size: 1.5vw;
    padding: 0.5vw 0;
}

.boiler-detail .accordion .quote-button {
    margin-top: 3vw;
}

.boiler-detail .accordion .quote-button button {
    width: 100%;
}

.boiler-detail .dimensions {
    justify-content: space-around;
    padding: 0.5vw;
    margin-bottom: 4vw;
}

.points-list.flexbox {
    align-items: center;
    margin-bottom: 1.5vw;
    font-size: 1.3vw;
}

.points-list img {
    max-width: 2.5vw;
    margin-right: 1.5vw;
}

.dimensions .col-md-4 {
    text-align: center;
    font-size: 1.5vw;
}

.dimensions .col-md-4:not(:last-of-type) {
    border-right: 1px solid #636569;
}

.included-accord p,
.heating-accord p,
.radiator-accord p {
    font-size: 1.25vw;
    margin-bottom: 2vw;
}

.heating-accord .points-list.flexbox {
    margin-bottom: 1vw;
    font-size: 1.05vw;
}

.heating-accord .points-list img {
    max-width: 2.5vw;
    margin-right: 1vw;
}

.radiator-card {
    border: 2px solid var(--color-skyblue);
    border-radius: 10px;
    padding: 2vw;
    margin: 0 2.5vw 0 0;
}

.radiator-card p {
    margin-bottom: 0;
}

.radiator-card h3 {
    font-size: 1.75vw;
    font-family: 'Museo 700';
    color: var(--color-skyblue);
}

.radiator-card select {
    margin-top: 1.5vw;
    padding: 0.5vw 1vw;
    font-size: 1.2vw;
    border: 1px solid var(--color-skyblue);
    background: white;
    border-radius: 6px;
    width: 63%;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url("../../../assets/images/Down-scroll.svg") no-repeat right;
    background-size: 50% 50%;
    background-position-x: 140%;
}

.radiator-card select::-ms-expand {
    display: none;
}

.choose-installation-date .quote-button button {
    display: flex;
    align-items: center;
    padding: 0.5% max(4%, 40px);
    width: 100%;
}

.choose-installation-date .quote-button button img {
    max-width: 2.5vw;
    margin-right: 1vw;
}

.boiler-detail .safe-installtion {
    justify-content: center;
}

.boiler-detail-right h3 {
    margin-bottom: 2vw;
    line-height: 2;
    font-size: 2.5vw;
}

.boiler-detail-right .white-card {
    background: white;
    border-radius: 10px;
    padding: 1vw;
}
.boiler-detail-right .white-card > .row > [class^="col-md-"]{
    display: flex;
    align-items: center;
}
.boiler-detail-right .white-card .row {
    padding: 1.5vw 0;
    font-size: 1.15vw;
    border-bottom: 1px solid #636569;
    margin: 0 auto;
}

.boiler-detail-right .white-card .row:last-of-type {
    border-bottom: none;
}

.boiler-detail-right .white-card .row .col-md-2 {
    padding: 0;
}

.boiler-detail-right .white-card .row .col-md-2,
.boiler-detail-right .white-card .row .col-md-2 span {
    text-align: right;
    font-family: 'Museo 700';
    color: var(--color-skyblue);
}

.black-card {
    background: black;
    color: white;
    font-family: 'Museo 700';
    border-radius: 10px;
    margin-top: 2vw !important;
    justify-content: space-between;
    padding: 0.75vw 1vw;
    font-size: 1.5vw;
}

.black-card .col-md-6 {
    font-family: 'Museo 700';
    width: auto !important;
}
/* .maxH50{
    max-height: 50px;
} */
.black-card .col-md-6:last-of-type {
    padding-top: 0 !important;
    text-align: right !important;
}

.black-card small {
    font-size: 0.875vw;
}
.boiler-detail .col-md-8 > .row > .col-md-6:first-of-type{
    position: relative;
    padding-top: 6%;
}
.boiler-detail .col-md-8 > .row > .col-md-6 img.best_buy_logo{
    position: absolute;
    top: 0%;
    right: -3%;
    max-width: 100%;
    width: 50%;
}
.email-quote div{
    cursor: pointer;
}
.checkout-page .white-date-box .quote-button > button{
    border-radius: 0.8vw;
    padding: 1.3% max(4%, 40px);
    font-size: 1.45vw;

}
.chooseDepo{
    font-size: 2.6vw;
}
@media only screen and (max-width: 960px){
    .choose-installation-date .quote-button button {
        /* padding: 5% max(2%, 20px); */
        padding: 2% max(2%, 20px);
        font-size: 2vw;
    }
    .chooseDepo{
        font-size: 2.8vw;
    }

}

.checkout-page .white-date-box .quote-button > button{
    border-radius: 0.8vw;
    padding: 1.3% max(4%, 40px);
    font-size: 1.45vw;
}
@media only screen and (max-width: 960px){
.choose-installation-date .quote-button button {
    /* padding: 5% max(2%, 20px); */
    padding: 2% max(2%, 20px);
    font-size: 2vw;
}
}

@media only screen and (max-width:767px) {
    .boiler-detail {
        clip-path: polygon(50% 100%, 100% 98%, 100% 0, 0 0, 0 98%) !important;
    }

    .boiler-detail h2 {
        font-size: 7vw;
    }

    .boiler-detail .row .col-md-8 .row .col-md-6 {
        text-align: center;
    }

    .boiler-detail>.row .col-md-6:first-of-type>img {
        max-width: 75%;
    }

    .boiler-detail>.row .col-md-6:last-of-type>img:first-of-type {
        max-width: 50%;
    }

    .boiler-detail>.row .col-md-6:last-of-type>img:last-of-type {
        margin-top: 4.5vw;
        max-width: 75%;
    }

    .boiler-detail .grey-title {
        font-size: 4.5vw;
    }

    .boiler-detail .grey-subtitle {
        margin-top: 1vw;
        margin-bottom: 2vw;
        font-size: 3.6vw;
    }

    .boiler-detail .grey-subtitle span {
        font-size: 5vw;
    }

    .boiler-detail .grey-card .quote-button:last-of-type {
        padding-left: calc(var(--bs-gutter-x) * .5);
    }

    .boiler-detail .grey-card .quote-button:last-of-type button {
        min-width: 16vw;
        font-size: 4.5vw;
        padding: 2vw 5.5vw;
    }

    .boiler-detail .recommended-boiler .email-quote>div {
        font-size: 3.65vw;
    }

    .boiler-detail .recommended-boiler .email-quote>div>img {
        max-width: 5.5vw;
    }

    .chooseDepo{
        font-size: 4vw;
    }

    .accordion-button {
        padding: 3vw 5vw;
    }
    .accordion-item{
        margin: 7vw 0 !important;
    }
    .accordion-button::after,
    .accordion-button:not(.collapsed)::after {
        width: 4vw !important;
        height: 4vw !important;
        background-size: 4vw !important;
    }

    .points-list.flexbox,.heating-accord .points-list.flexbox {
        font-size: 3.5vw;
        margin-bottom: 1.5vw;
    }

    .points-list img, .heating-accord .points-list img {
        max-width: 6.5vw;
        margin-right: 1.5vw;
    }

    .included-accord p,
    .heating-accord p,
    .radiator-accord p {
        font-size: 3.25vw;
        margin-bottom: 4vw;
    }

    .radiator-card h3 {
        font-size: 3.75vw;
        text-align: center;
        margin-top: 3vw;
    }

    .radiator-card select {
        margin-top: 1.5vw;
        padding: 0.5vw 1vw;
        font-size: 4.2vw;
        width: 100%;
    }

    .radiator-card img{
        width: 66%;
        /* height: %; */
    }

    .choose-installation-date .quote-button button {
        /* padding: 2.5% max(2%, 20px);
        font-size: 4vw; */
        padding: 4.5% max(2%, 20px);
        font-size: 3vw;
    }

    .choose-installation-date .quote-button button img {
        max-width: 5vw;
        margin-right: 1vw;
    }

    .boiler-detail-right {
        margin-top: 7vw;
        padding-right: calc(var(--bs-gutter-x) * 1);
        padding-left: calc(var(--bs-gutter-x) * 1);
    }

    .boiler-detail-right h3 {
        margin-bottom: 2vw;
        font-size: 8vw;
        margin-top: 5vw;
    }
    .boiler-detail-right .white-card{
        padding: 4vw;
        margin-right: 0;
        margin-left: 0;
    }
    .boiler-detail-right .white-card .row {
        font-size: 4vw;
        padding: 3.5vw 0;
    }

    .boiler-detail-right .white-card>.row>.col-md-3 {
        text-align: center;
        max-width: 20%;
        padding-right: 0;
        padding-left: 0;
        padding-top: 0;
    }

    .boiler-detail-right .white-card>.row>.col-md-7 {
        /* max-width: 65%; */
        max-width: 59%; /* change by David 19-10-2021 */
    }

    .boiler-detail-right .white-card>.row>.col-md-2 {
        max-width: 15%;
    }

    .boiler-detail-right .white-card>.row>.col-md-3 img{
        max-width: 100%;
    }
    .black-card {
        margin-top: 5vw !important;
        padding: 2.5vw 3vw;
        font-size: 4.5vw;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .black-card .col-md-6:first-of-type {
        max-width: 30%;
    }

    .black-card .col-md-6:last-of-type {
        max-width: 70%;
    }
    .black-card small {
        font-size: 2.875vw;
    }
    .accordion-button{
        padding: 3.5vw 4.5vw !important;
        font-size: 4vw !important;
    }
    .desc-list {
        font-size: 4vw;
        padding: 1.5vw 0;
    }
    .boiler-detail .accordion .quote-button button {
        padding: 2.5% max(2%, 20px);
        font-size: 5vw;
    }
    .dimensions .col-md-4 {
        font-size: 3.2vw;
        width: 33.33%;
    }
    .heating-accord .col-md-4{
        text-align: center;
        margin-bottom: 5vw;
    }
    .heating-accord .col-md-4 img{
        max-width: 50%;
    }
    .boiler-detail .col-md-8 > .row > .col-md-6:first-of-type{
        padding-top: 8%;
    }
    .boiler-detail .col-md-8 > .row > .col-md-6 img.best_buy_logo {
        top: 0%;
        right: 6%;
        max-width: 75%;
        width: 35%;
    }

    .checkout-page .white-date-box .quote-button > button{
        font-size: 3.45vw;
    }
}

/* Installtion date section Start */
.choose-installation-date .quote-button button{
    justify-content: center;
}

.text-right {
    text-align: right;
}

.date-icon-image {
    width: 3vw;
}

.pr-5 {
    padding-right: 15px;
}

/* Calander Css Start */
.react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    height: 41px;
    margin-bottom: 1em;
  }
  .react-calendar__navigation button {
    min-width: 41px;
    background: none;
  }
  .react-calendar__navigation .react-calendar__navigation__label:enabled:hover,
  .react-calendar__navigation .react-calendar__navigation__label:enabled:focus {
    background-color: #FFF;
  }
  .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
  }
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__tile--now {
    background: #ffff76;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
  }
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  .react-calendar__tile--active {
    background: #006edc;
    color: white;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }

.row > .react-calendar  {
    padding: 0;
}

.react-calendar__navigation {
    background-color:none;
}

.react-calendar__navigation__prev2-button{
    display: none;
}

.react-calendar__navigation__next2-button {
    display: none;
}

.react-calendar__navigation .react-calendar__navigation__prev-button{
    background-color: var(--color-skyblue);
    border-radius: 50%;
    color: #FFF;
    font-size: 50px;
}

.react-calendar__navigation .react-calendar__navigation__next-button{
    background-color: var(--color-skyblue);
    border-radius: 50%;
    color: #FFF;
    font-size: 50px;
}
.react-calendar{
    border: none;
}

.react-calendar__month-view__days {
    border : 1px solid var(--color-skyblue);
    padding-top: 5px;
}

.react-calendar__month-view__weekdays__weekday{
    padding: 15px 0px;
}

.react-calendar__month-view__weekdays{
    background-color: var(--color-skyblue);
    color: #FFF;
}


.react-calendar__navigation__arrow:enabled:hover,
.react-calendar__navigation__arrow:enabled:focus {
    background-color: var(--color-skyblue);
}

.react-calendar__navigation__label:enabled:hover,
.react-calendar__navigation__label:enabled:focus {
    background-color: #FFF;
}

.react-calendar abbr[data-bs-original-title], .react-calendar abbr[title] {
    text-decoration: none;
}

.react-calendar__tile {
    padding: 1.5em 0.5em;
}

.react-calendar {
    width: 100%;
}

.react-calendar__month-view__days button {
    min-height: 60px;
    padding: 0em 0.50em;
    text-align: end;
    position: relative;
    /* margin: 5px; */
}

.react-calendar__month-view__days button abbr {
    position: absolute;
    top: 2px;
    right: 5px;
    color: var(--color-skyblue);
}

.react-calendar__tile--active abbr, .react-calendar__tile--active:enabled:hover abbr, .react-calendar__tile--active:enabled:focus abbr, .react-calendar__tile--now abbr {
    color: #FFF !important;
    font-weight: 700;
}

/* .react-calendar__month-view__days button:after {
    content: 'Full';
    left: 10px;
    position: absolute;
    color: #2a2723;
} */

.react-calendar__tile:disabled:after{
    content:  '';
}

.react-calendar__tile--now.my_available_date:after{
    content: url('./../../../assets/images/resultspage/date-white-tick.png');
    left: 10px;
    position: absolute;
}

.react-calendar__month-view__days__day.my_available_date:after {
    content: url('./../../../assets/images/resultspage/date-tick.png');
    left: 10px;
    position: absolute;
}

.react-calendar__month-view__days__day.my_available_date.white:after {
    content: url('./../../../assets/images/resultspage/date-white-tick.png');
}

.react-calendar__tile--active, .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus{
    /* background-color: #90bf35; */
    /* background: #1087ff; */
    background: var(--color-skyblue);
}
.react-calendar__tile--now, .react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {
    /* background: #1087ff; */
    /* BLUE COLOR TO THE CURRENT DATE */
    background-color: #90bf35;
}

.react-calendar__tile--now:disabled{
    background-color: #90bf35;
}
.react-calendar__tile--now:disabled::after{
    color:#FFF !important;
}

.react-calendar__month-view__days__day.booked_date:after{
    content: 'Full';
    left: 10px;
    position: absolute;
    color: #2a2723;
}

.react-calendar__navigation .react-calendar__navigation__label {
    color: var(--color-skyblue);
}

.react-calendar__navigation__label__labelText{
    font-weight: 700;
    font-size: 2vw;
}
/* Calander Css End */

.mt-5 {
    margin-top: 5px;
}

.pl-2 {
    padding-left: 10px;
}

.pr-2{
    padding-right: 10px;
}

.acc-content-text {
    text-align: left;
}

.acc-content-text p{
    font-size: 1.15vw;
}

.installation-date .quote-button button {
    width: 75% !important;
}

/* select {
    background-position-x: 120%;
} */

.prename-options{
    background-position-x: 120%;
}

input {
    padding: 0.5vw 1vw;
    border: 1px solid var(--color-skyblue);
    border-radius: 6px;
    width: 100%;
}

.installation-date-col{
    padding-top: unset !important;
    text-align: left !important;
}

/* .control-label {
    color:#2a2723;
    font-weight: 700;
} */

.black-label {
    color:#2a2723;
    font-weight: 700;
}

.full-address-link {
    font-size: 1.25vw;
    text-decoration: none;
    cursor: pointer;
}

.apply_btn .quote-button {
    margin-top: 1.7vw !important;
    width: auto !important;
}

.apply_btn .quote-button  button{
    font-size: 1.5vw !important;
    width: auto !important;
    border-radius: 8px !important;
}

.payment_tab {
    padding: 1vw;
    border-radius: 12px;
    background-color: #636569;
    color: #fff;
    cursor: pointer;
}

.payment_tab p{
    padding-left: 10px;
    margin-bottom: 0px;
}

.payment_tab .price {
    font-size: 2vw;
}

.payment_tab .price span{
    font-size: 1.25vw;
}

.payment_tab.active {
    background-color: var(--color-skyblue);   
}

.border-tab {
    border:1px solid var(--color-skyblue);
    border-radius: 5px;
    padding: 20px;
}

.control-label.color-skyblue{
    color:  var(--color-skyblue);
}

.pyment-form .dropdown {
    background-position-x: 140%;
}

.range-slider {
    width: 100%;
    height: 3vw;
    background-color: white;
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    background: transparent;
    border:none;
    padding: 0px;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    background: #636569;
    border: none;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 2vw;
    width: 2vw;
    border-radius: 50%;
    background: var(--color-skyblue);
    margin-top: -0.7vw;
}

input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;
}

.text-right {
    float: right;
}

.info p {
    margin-bottom: 0px;
}

.accept-tc > input {
    -webkit-appearance: none;
    border:none;
}

.box {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 18px;
    }
      /* Hide the default style of the checkbox */
      input[type=checkbox] {
        visibility: hidden;
      }
      /* Create a custom checkbox */
      .mark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-image:url('../../../assets/images/checkbox-blank.png');
      }
      /* Specify the background color for the checkbox while hovering */
      .box:hover input + .mark {
        background-image:url('../../../assets/images/checkbox-blank.png');
        background-color: transparent;
      }
      
      /* Specify the background color for the checkbox when it is checked */
      .box input:checked + .mark {
        background-image:url('../../../assets/images/checked.png');
        background-color: transparent;
      }
      /* Checkmark to be shown in checkbox */
      /* It will not be shown when not checked */
      .mark:after {
        content: "";
        position: absolute;
        display: none;
      }
      /* Display checkmark when checked */
      .box input:checked + .mark:after {
        display: block;
      }

.hide-on-desktop {
    display: none;
}

.custom-card-input {
    padding: 0.5vw 1vw;
    border: 1px solid  var(--color-skyblue);
    border-radius: 6px;
    width: 100%;
}

.card_input {
    border:none
}

input:focus{
    outline: none;
}

.custom-card-input .image_card  {
    width: 5%;
}

.custom-card-input .card_input  {
    width: 59%;
}

.custom-card-input .exp_input  {
    width: 20%;
    border:none
}

.custom-card-input .cvc_input  {
    width: 15%;
    border:none
}

.custom-card-input input::-webkit-input-placeholder {
    font-weight: 500;
    color:#2a2723;
}

.payment-confrim-btn .quote-button {
    margin-top: 1vw !important;
}

.apply-btn {
    background-color: var(--color-skyblue);
    color: #fff;
}

.no-border {
    border: none;
}
@media only screen and (max-width: 1280px){
    .date-icon-image {
        width: 5vw;
    }
}

@media only screen and (max-width: 1024px) {
    .apply_btn .quote-button {
        margin-top: 2.2vw !important;
    }

    .apply_btn .quote-button button {
        font-size: 2.5vw !important;
    }

    .custom-card-input .card_input {
        width: 55%;
    }

    .custom-card-input .cvc_input {
        width: 15%;
    }

    .react-calendar__navigation{
        min-height: 35px;
    }

    .react-calendar__navigation button{
        min-width: 41px;
    }

    .react-calendar__navigation .react-calendar__navigation__next-button {
        font-size: 35px;
    }
}
@media only screen and (max-width:960px) and (min-width:768px){
    .your_details.accordion-item .row .col-md-2, .your_details.accordion-item .row .col-md-5{
        padding-left: 5px;
        padding-right: 5px;
    }
   
}
@media only screen and (max-width:780px){
    input[type=range]::-webkit-slider-runnable-track {
        height: 7px;
    }

    input[type=range]::-webkit-slider-thumb {
        height: 1.5vw;
        width: 1.5vw;
        margin-top: -1vw;
    }
}

@media only screen and (max-width:767px){

    .custom-card-input img  {
        width: 20px;
    }

    .custom-card-input input::-webkit-input-placeholder {
        font-weight: 500;
        font-size: 3vw;
        color:#2a2723;
    }

    .custom-card-input .card_input{
        width: 50%;
    }

    .date-icon-image {
        width: 10vw;
    }

    .hide-on-desktop {
        display: show;
    }

    .form-group{
        margin-bottom: 10px;
    }

    .installation-date.pt-3{
        padding-top: unset !important;
    }

    select {
        background-position-x: 140%;
    }

    .included-accord p, .heating-accord p, .radiator-accord p{
        font-size: 4.5vw;
        margin-bottom: 2vw;
    }

    .full-address-link {
        font-size: 4.5vw;
    }

    .row.pl-2.pr-2 {
        padding-left: unset;
        padding-right: unset;
    }

    .payment_tab {
        margin-bottom: 10px;
    }

    input[type=range]::-webkit-slider-runnable-track {
        width: 100%;
        height: 10px;
        background: #636569;
        border: none;
    }
    
    input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
        border: none;
        height: 4vw;
        width: 4vw;
        border-radius: 50%;
        background: var(--color-skyblue);
        margin-top: -1.2vw;
    }
    
    input[type=range]::-ms-track {
        width: 100%;
        cursor: pointer;
    }

    .pyment-form p {
        text-align: left;
    }

    .hide-on-mobile {
        display: none;
    }

    .checkout-page h3 {
        font-size: 4vw;
    }

    .checkout-page .accordion-header {
        margin-top: 2vw;
    }

    .checkout-page .accordion-header .accordion-button {
        font-size: 5vw !important;
    }

    .acc-content-text p {
        font-size: 4.5vw;
        text-align: left;
    }

    .react-calendar__navigation__label__labelText{
        font-size: 4vw;
    }

    .prename-options {
        background-position-x: 140%;
    }

    .apply_btn .quote-button {
        margin-top: 4vw !important;
    }

    .apply_btn .quote-button button {
        font-size: 3.5vw !important;
    }

    .react-calendar__month-view__days button {
        min-height: 60px;
        padding: 0em 0.10em;
        text-align: end;
        position: relative;
        margin: 7px;
    }
}
/* Installtion date section End */

/* TRV Prices Start*/
.white-card.row > .row {
    align-items: center;
}
/* TRV Prices End */

/* Validation message Start */
.error {
    color: red
}
/* Validation Message End*/

@media only screen and (max-width:350px){
    .pos-absolute, .boiler-card.one-line-title .pos-absolute{
        top: -40vw;
    }
}

/* Calander css */
@media only screen and (max-width:1200px){
    .payment_tab{
        padding: 1vw;
    }
}
@media only screen and (max-width:767px){
    .react-calendar__navigation{
        height: 6vw;
    }

    .react-calendar__navigation .react-calendar__navigation__next-button {
        font-size: 5vw;
    }

    .react-calendar__navigation button {
        min-width: 6vw;
    }
}

@media only screen and (max-width:500px){
    
    
    .react-calendar__navigation{
        height: 8vw;
    }

    .react-calendar__navigation button{
        width: 8vw;
    }

    .react-calendar__navigation .react-calendar__navigation__next-button, .react-calendar__navigation .react-calendar__navigation__prev-button {
        font-size: 6vw;
    }

    button {
        font-size: 4vw;
    }
    /* .bgColor-skyblue{
        color: green !important;
    } */
}

/* Instaltion section */
.white-date-box {
    background: white;
    border-radius: 10px;
    padding: 1vw;
}

.react-calendar__month-view__weekdays{
    font-size: 1.5vw;
    font-weight: normal;
    text-transform: capitalize;
}

.react-calendar__month-view__days button{
    border: 1px solid #2a2723;
}

.react-calendar__month-view__days button.before_date{
    opacity: 0.4;
}

.react-calendar__month-view__days button.booked_date{
    opacity: 0.8;
}

.react-calendar__month-view__days button.before_date abbr{
    color: #2a2723;
}

.react-calendar__month-view__days button.booked_date abbr{
    color: #2a2723;
}

.react-calendar__month-view__days__day--neighboringMonth{
    visibility: hidden;
}

.react-calendar__month-view__days button{
    /* margin: 0.4vw; */
}

.payment_tab p {
    font-size: 1.5vw;
}

.installation_address p {
    font-size: 1.5vw;
}

.accept-tc a {
    text-decoration: none;
}

.boiler-detail>.row .col-md-6.eng_text {
    text-align: left;
}

@media only screen and (max-width:1367px){
    .payment_tab p {
        font-size: 1.4vw;
    }
}

@media only screen and (max-width:767px){
    .react-calendar__month-view__weekdays {
        font-size: 3.5vw;
    }

    .payment_tab p, .payment_tab .price span {
        font-size: 3.5vw;
    }

    .payment_tab p.price {
        font-size: 4.5vw;
    }

    .installation_address p {
        font-size: 4.5vw;
    }
}

.react-calendar__month-view__days{
    justify-content: space-between;

}

.react-calendar__month-view__days__day{
    flex-basis: 12% !important;
    max-width: 12% !important;
    margin: 1% !important;
}

.InVisible {
    visibility: hidden;
    height: 0px;
}

.custom-card-input{
    height: 3vw;
    line-height: inherit;
}

.custom-card-input .image_card, #card-number, #expiration-date, #cvv {
    height: 100%;
    float: left;
}

.custom-card-input .image_card {
    margin-right: 1%;
    width: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.image_card img {
    vertical-align: unset;
}

select:focus-visible {
    outline: none;
}

@media only screen and (max-width:960px){
    .custom-card-input{
        height: 5vw;
    }
}

@media only screen and (max-width:767px){
    .custom-card-input {
        height: 4vw;
    }
}

@media only screen and (max-width:730px){
    .custom-card-input {
        height: 5vw;
    }
}

.custom-card-input {
    display: flex;
}

.custom-card-input .card_input {
    width: 65%;
}

.custom-card-input .exp_input{
    width: 25%;
}

.custom-card-input .cvc_input{
    width: 15%;
}

@media only screen and (max-width:500px){
    .custom-card-input {
        height: 7vw;
    }

    .custom-card-input {
        display: block;
    }

    .custom-card-input .card_input {
        width: 80%;
    }    

    .custom-card-input .exp_input{
        width: 48%;
        padding: 0.5vw 1vw;
        border: 1px solid var(--color-skyblue);
        border-radius: 6px;
        margin: 4% 0% 0% -1%;
        height: 7vw !important;
    }
    
    .custom-card-input .cvc_input{
        width: 48%;
        padding: 0.5vw 1vw;
        border: 1px solid var(--color-skyblue);
        border-radius: 6px;
        margin: 4% 0% 0% 5%;
        height: 7vw !important;
    }

    .react-calendar__month-view__days__day.booked_date:after {
        content: 'Full';
        left: 2px;
        position: absolute;
        color: #2a2723;
        font-size: 3vw;
        bottom: 0;
    }

    .react-calendar__month-view__days__day.my_available_date:after {
        left: 2px;
        bottom: 0px;
    }

    .react-calendar__month-view__days button {
        min-height: 45px;
    }

    .react-calendar__month-view__days button abbr{
        font-size: 3vw;
    }

    .react-calendar__month-view__days__day.my_available_date:after{
        content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAMAAAC67D+PAAAACXBIWXMAABcSAAAXEgFnn9JSAAAA0lBMVEVHcEwApc8Apc8Apc8Apc8Apc8ApM4Apc8Apc8Apc8Apc4Apc8Apc8Apc4Apc8ApM8Apc8ApM4Apc8Apc8Apc8Apc8Apc8ApM4ApM4Apc8Apc8Apc8Apc8Apc8ApM8Apc8Apc4Apc8ApM8Apc8Apc8ApM8Apc8Apc8Apc4ApM8ApM8Apc8Apc8Apc8ApM8Apc8Apc8Apc8Apc8Apc8Apc8Apc8ApM4ApM8Apc8Apc8Apc8Apc4Apc8Apc8Apc8Apc8Apc8Apc8Apc8Apc8Apc8Apc/RHfc+AAAARnRSTlMAkMelFAHArwIJqDZdGR8ERgIvtTswdAEQirLQ1VQMMwMOAbjOcQRZCRTOYByiy80tEUmPQkzGJYkSw1mswKGMGr+0e74oi6UcXgAAAGpJREFUCNcNyucagWAAgNE3la9siqxkF6Fpb3H/t6S/5zloOGIw7HfVJrhzezRRzAaE6xXQbpUrbH08xkatWmC3yXHRgRKBoh8jsbSQEXsel5ipXiQ98HrmpydBdv/BOZnVUbl+P7f3SeMPylMJmO4ZAgEAAAAASUVORK5CYII=);
    }

    .react-calendar__month-view__days__day.my_available_date.white:after{
        content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAMAAAC67D+PAAAACXBIWXMAABcSAAAXEgFnn9JSAAAAxlBMVEVHcEz////////////////////////////////////////////////////+/v7////////////+/v7////////+/v7////+/v7////////////////////////+/v7////////////+/v7////+/v7////////////+/v7////////////+/v7////////////////////////////////+/v7///////////////////////////////////////////////85YuWfAAAAQnRSTlMAr1mQAsAJARQEx6ilNl0fGUYCL7UJOwEwdBCKA7JU0AzVrzMOuM5xzs0tYMscoo8RSUJMxiWJEsMorKGMGr+0e77Y/YKbAAAAaUlEQVQI1w3K1RaCQABAwQuy7IJiInYnIGEH5v//lPs6Z7BYzCezUb/TBHM9ni5V0IBoq4Buy6uSxtgM/VrdZZdo3PSgTKjsc244kgrGkeK2R7YFhxOvpz6DEnzFHa7ZykFi/j6P98XiD7JECJsesuEzAAAAAElFTkSuQmCC') !important;
    }
}
/* Instaltion ssection */
/* Loader Start */
.progressOverley{
    /* position: absolute;
    top: 70%;
    left: 0%;
    text-align: center;
    width: 100% */
    position: fixed;
    height: 100vh;
    top: 0;
    background: #0b0b0b;
    opacity: 0.9;
    left: 0;
    z-index: 9999;
    width: 100vw;
}

@media only screen and (max-width:767px){
    .progressOverley{
        /* top: 30%; */
    }
}

.full-address-link:hover{
    color: var(--color-skyblue);
}

.react-calendar__tile--active.my_available_date:after, .react-calendar__tile--active.my_available_date:enabled:hover:after, .react-calendar__tile--active.my_available_date:enabled:focus:after, .react-calendar__tile--now.my_available_date:after, .react-calendar__tile--now.my_available_date:enabled:hover:after, .react-calendar__tile--now.my_available_date:enabled:focus:after{
    content: url('./../../../assets/images/resultspage/date-white-tick.png') !important;
}

/* Loader End */

/* Success page */
.order_heading {
    font-size: 2.2vw;
}

.your_order_details_text p{
    font-size: 1.4vw;
}

.happen_box {
    background: #626469;
    border-radius: 1vw;
    color: #FFF;
    padding: 1vw 3vw;
    margin-top: 3vw;
}

.happen_heading {
    font-size: 2.5vw;
}

.your_order_details_text > .col-md-6 {
    /* padding: 0vw !important; */
}

.boiler-detail>.row .col-md-6:last-of-type>img.s_hand_image {
    /* margin-left: -5vw; */
    max-width: 100%;
}

.boiler-detail>.row .col-md-8> .row.smiley_face {
    margin-bottom: 1vw;
}

.smiley_face p {
    font-size: 2.5vw;
    margin: 0vw;
}

.smiley_face div {
    align-self: center;
}

@media only screen and (max-width:767px){
    .recommended-boiler h1.success_heading {
       font-size: 5.5vw;
   } 

   .your_order_details_text p{
        font-size: 2.4vw;
        text-align: left;
   }
   .smiley_face p{
       font-size: 2.5vw;
       text-align: left;
   }

   .your_order_details_text p{
        font-size: 4.4vw;
        margin-bottom: 0vw;
    }
    .smiley_face p{
    font-size: 3.7vw;
    }

    .happen_heading {
        font-size: 5.5vw;
        font-weight: 800;
    }

    .order_heading {
        font-size: 4.2vw;
    }
}

@media only screen and (max-width:520px){
    .your_order_details_text .col-sm-3 {
        max-width: 25%;
    }

    .your_order_details_text .col-sm-9 {
        max-width: 75%;
    }

    .chooseDepo{
        font-size: 4.5vw;
    }
}

.your_order_details_text a, .your_order_details_text a:hover {
    text-decoration: none;
    color: var(--color-skyblue);
}

.accept-tc input[type=checkbox]{
    display: none;
}

.z-index-999{
    z-index: 99999;
}

.your_order_details_text .points-list.flexbox {
    align-items: flex-start;
}

/* @media only screen and (max-width: 520px){
    .choose-installation-date .quote-button button {
        font-size: 3vw;
    }
    .react-calendar__navigation .react-calendar__navigation__next-button, .react-calendar__navigation .react-calendar__navigation__prev-button{
        padding-bottom: 0.3em;
        padding-right: 0.3em;
    }
    /* .react-calendar__navigation .react-calendar__navigation__prev-button {
        
    } */
/* } */

@media only screen and (max-device-width: 380px){
    .choose-installation-date .quote-button button {
        /* font-size: 2.6vw; */
    }
    .chooseDepo{
        font-size: 5vw;
    }
}

@media only screen and (max-device-width: 380px) and (min-device-width: 365px){
    .react-calendar__navigation .react-calendar__navigation__next-button, .react-calendar__navigation .react-calendar__navigation__prev-button{
        padding-bottom: 0.2em;
        padding-top: 0.1em;
        /* padding-left: 0.1em; */
        margin-left: -0.3rem;
    }
}

